<template>
  <v-app-bar
    height="52px"
    flat
    clipped-right
    app
    :scroll-off-screen="false"
    :elevate-on-scroll="false"
    :hide-on-scroll="false"
    fixed
    id="navbar_drawer"
  >
    <v-btn icon @click="miniSideNav">
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <div id="navbar_search" class="mb_hidden">
      <v-autocomplete
        :items="animals"
        v-model="cow"
        data-vv-name="search"
        outlined
        append-icon="mdi-magnify"
        placeholder="Droptag"
        class="rounded-lg mr-3"
        :background-color="!$vuetify.theme.dark ? 'white' : ''"
        hide-no-data
        :return-object="true"
        @focus="focus_animal_select()"
        :search-input.sync="selection_query_params.search"
      >
        <template v-slot:item="{ item }">
          <router-link
            style="width: 96%"
            :to="'/animals/' + item.text"
            class="search_link"
          >
            <v-row no-gutters class="mt-1 mb-1 search_row"
              ><v-col cols="3"
                ><v-img
                  v-if="
                    item.image_id
                  "
                  height="50px"
                  width="50px"
                  class="rounded"
                  aspect-ratio="1"
                  :src="cropped_url(item.image_id, item.image_timestamp, item.customer_id, 'head')"
                />

                <v-img
                v-else
                  height="50px"
                  width="50px"
                  class="rounded"
                  aspect-ratio="1"
                  :src="placeholder_img"
                />
                </v-col
              >

              <v-col class="search_col_detail ml-2"
                ><span style="width: 96%; display: inline-block">
                  <v-chip
                    style="height: 24px; display: inline-block"
                    v-if="item.text"
                    :color="
                      item.tag_color &&
                      item.tag_color != 'UNDEFINED'
                        ? 'oc_' +
                          item.tag_color.toLowerCase() +
                          ' darken-1'
                        : 'grey darken-1'
                    "
                  >
                    <span
                      style="color: white; font-weight: 500; font-size: 12px" 
                      >{{ item.text }}</span
                    ></v-chip
                  >
                  <!-- <span style="float: right">
                    {{ item.name }}
                  </span> -->
                </span>
                <span class="search_rfid">{{
                  item.name
                }}</span></v-col
              ></v-row
            >
            <v-divider />
          </router-link>
        </template>
        <!-- <template v-slot:item.ID="{item}">
              <router-link :to="'cow/'+ item.ID"></router-link>
          </template>-->
      </v-autocomplete>
    </div>
    <v-spacer></v-spacer>

    <v-icon v-if="!$vuetify.theme.dark" class="weather_icon" color="#3391b0"
      >mdi-temperature-celsius</v-icon
    >
    <v-icon v-if="$vuetify.theme.dark" class="weather_icon" color="#add8e6"
      >mdi-temperature-celsius</v-icon
    >
    <span v-if="weatherX.currentTemp" class="weather_icon weather">
      {{ weatherX.currentTemp }}
    </span>
    <v-spacer></v-spacer>
    <v-icon v-if="!$vuetify.theme.dark" class="weather_icon" color="#3391b0"
      >mdi-weather-pouring</v-icon
    >
    <v-icon v-if="$vuetify.theme.dark" class="weather_icon" color="#add8e6"
      >mdi-weather-pouring</v-icon
    >
    <span v-if="weatherX.currentTemp" class="weather_icon weather">
      {{ weatherX.currentWeather }}
    </span>
    <!-- <v-spacer></v-spacer>
    <v-icon v-if="!$vuetify.theme.dark" class="weather_icon" color="#3391b0"
      >mdi-laser-pointer</v-icon
    >
    <v-icon v-if="$vuetify.theme.dark" class="weather_icon" color="#add8e6"
      >mdi-laser-pointer</v-icon
    >
    <span v-if="weatherX.currentTemp" class="weather_icon weather">{{
      weatherX.uv
    }}</span> -->
    <v-spacer class="mb_hidden"></v-spacer>
    <v-icon v-if="!$vuetify.theme.dark" class="weather_icon" color="#3391b0"
      >mdi-weather-windy</v-icon
    >
    <v-icon v-if="$vuetify.theme.dark" class="weather_icon" color="#add8e6"
      >mdi-weather-windy</v-icon
    >
    <span v-if="weatherX.currentTemp" class="weather_icon weather">
      {{ weatherX.wind_speed }}
    </span>
    <v-spacer class="mb_hidden"></v-spacer>
    <!-- <v-icon color="green" large>mdi-checkbox-marked-circle</v-icon>
      <span id="weather">BETSY</span> -->

    <!-- notifications -->
    <!-- <v-badge
        :content="notification_items.length"
        :value="notification_items"
        color="primary"
        overlap
        id="notifications"
        v-model="badge"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              large
              color="red"
              @click="badge = false"
              >mdi-bell</v-icon
            >
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in notification_items"
              :key="index"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <br />
              <router-link :to="{ name: item.link }">go</router-link>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-badge> -->
    <span style="width: 240px; padding-right: 18px" class="ml-3"
      ><v-select
        v-model="chosen_collaboration"
        hide-details
        :items="collaborations[0]"
        dense
        rounded
        outlined
        class="assign_select"
        full-width
      ></v-select
    ></span>

    <add-button class="mb_hidden" v-if="$store.getters.getCustomerID" />
    <!-- <v-btn icon class="notification_btn" @click="turn_notifications"
      ><v-icon large color="primary" dark>mdi-bell-circle</v-icon></v-btn
    > -->
    <v-btn icon class="account">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar size="30" color="oc-grey" class="image-border elevation-4">
            <v-img
              v-bind="attrs"
              v-on="on"
              :src="customer_picture + '?dummy=' + dummy_nums"
            >
              <template v-slot:placeholder>
                <v-icon
                  large
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  style="height: 100%; width: 100%; right: 1px"
                  data-cy="btn_nav_user_icon"
                  >mdi-account-circle</v-icon
                >
              </template>
            </v-img>
          </v-avatar>
        </template>
        <v-list style="z-index: 51 !important">
          <v-list-item to="/account">
            <v-list-item-icon>
              <v-icon color="primary">mdi-account-cowboy-hat</v-icon>
            </v-list-item-icon>

            <!-- <v-list-item-title v-if="user">{{
              user.attributes.email
            }}</v-list-item-title> -->
            <v-list-item-title v-if="user" data-cy="btn_nav_user_myAccount"
              >My Account</v-list-item-title
            >
          </v-list-item>
     
          <!-- <v-list-item to="/customersettings">
            <v-list-item-icon>
              <v-icon color="primary">mdi-card-account-details-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-if="user" data-cy="btn_nav_user_customer"
              >Customer</v-list-item-title
            >
          </v-list-item> -->
          <v-list-item to="/sites">
            <v-list-item-icon>
              <v-icon color="primary">mdi-barn</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              v-if="
                user &&
                $store.getters.getSites &&
                $store.getters.getSites.length == 1
              "
              >Site</v-list-item-title
            >
            <v-list-item-title
              v-else-if="
                user &&
                $store.getters.getSites &&
                $store.getters.getSites.length != 1
              "
              >Sites</v-list-item-title
            >
          </v-list-item>
          <v-list-item to="/sharing">
            <v-list-item-icon>
              <v-badge
                v-if="collaborations[1]"
                bordered
                color="oc_orange"
                overlap
                dot
              >
                <v-icon color="primary"
                  >mdi-account-group-outline</v-icon
                > </v-badge
              ><v-icon v-else color="primary">mdi-account-group-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-if="user">Sharing</v-list-item-title>
          </v-list-item>
          <v-list-item to="/notification-settings">
            <v-list-item-icon>
              <v-icon color="primary">mdi-bell</v-icon>
            </v-list-item-icon>

            <!-- <v-list-item-title v-if="user">{{
              user.attributes.email
            }}</v-list-item-title> -->
            <v-list-item-title v-if="user"
              >Notification</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            target="_blank"
            :href="`https://checkout.onecup.ai/p/login/5kAeZ12SSgVJ3mM144?prefilled_email=${$store.getters.getUser.attributes.email}`"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-open-in-new</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-if="user">Billing</v-list-item-title>
          </v-list-item>
          <v-list-item @click="open_tutorial">
            <v-list-item-icon>
              <v-icon color="primary">mdi-help-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Tutorial</v-list-item-title> </v-list-item
          >

          <!-- <v-list-item to="/map">
            <v-list-item-icon>
              <v-icon color="primary">mdi-map</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-if="user" data-cy="btn_nav_user_map"
              >Map</v-list-item-title
            >
          </v-list-item> -->
          <!-- <v-list-item v-if="user" to="/settings/sites">
            <v-list-item-icon>
              <v-icon>mdi-barn</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sites</v-list-item-title>
          </v-list-item> -->
          <!-- <v-list-item v-if="user" to="/settings/hardware">
            <v-list-item-icon>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Hardware</v-list-item-title>
          </v-list-item> -->
          <!-- <v-list-item v-if="user" to="/settings/jetson">
            <v-list-item-icon>
              <v-icon>mdi-router-wireless</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Jetson</v-list-item-title>
          </v-list-item>
            <v-list-item v-if="user" to="/settings/wifi">
            <v-list-item-icon>
              <v-icon>mdi-wifi</v-icon>
            </v-list-item-icon>
            <v-list-item-title>WIFI</v-list-item-title>
          </v-list-item>  -->

     
          <!--   <v-list-item @click="open_version">
             <v-list-item-icon>
              <v-icon color="primary">mdi-alpha-v-circle-outline</v-icon>
            </v-list-item-icon> 
            <v-list-item-title 
              >v {{ version["version"] }}</v-list-item-title
            >
          </v-list-item> -->
          <v-list-item>
            <feedback></feedback>
          </v-list-item>
          <v-list-item v-if="user" @click="logOut">
            <v-list-item-icon>
              <v-icon color="primary">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title data-cy="btn_nav_user_signout"
              >Sign out</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>
    <tutorial />
    <version-summary />
  </v-app-bar>
</template>

<script>
import { mapActions } from "vuex";
import { eventBus } from "../main";
import addButton from "./addButton.vue";
import Tutorial from "./Tutorial.vue";
import Feedback from "@/components/Feedback";

import VersionSummary from "./VersionSummary.vue";
import placeholder_img from "@/assets/cover_brown.png";
import moment from "moment";


//import version from "../data/version.json";

export default {
  components: {
    addButton,
    Tutorial,
    VersionSummary,
    Feedback,
  },
  data() {
    return {
      placeholder_img: placeholder_img,

      // date: new Date(),
      // version: version,
      badge: true,
      notification_items: [
        { title: "cow has ran away", link: "dashboard" },
        { title: "BETSY recent update", link: "login" },
        { title: "new message", link: "support" },
      ],

      cruds: [
        ["Create", "add"],
        ["Read", "insert_drive_file"],
        ["Update", "update"],
        ["Delete", "delete"],
      ],
      account_items: [{ name: "cattle co" }],
      cow: "",
      drawer: true,
      messages: Number,
      show: false,
      chosen_collaboration: null,
      dummy_nums: "9999999",

      selection_query_params: {
        search: null,
        active: false,
      },
    };
  },

  computed: {
    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },
    collaborations() {
      var collaborations = [];
      var pending = false;
      var permission = {};

      if (this.$store.getters.getUserDDB) {
        for (const i in this.$store.getters.getCollaborators) {
          if (!this.$store.getters.getCollaborators[i].approved) {
            pending = true;
          } else {
            collaborations.push({
              text: this.$store.getters.getCollaborators[i].owner_email,
              value: this.$store.getters.getCollaborators[i].customer_id_owner,
            });

            permission[
              this.$store.getters.getCollaborators[i].customer_id_owner
            ] = this.$store.getters.getCollaborators[i].permission;
          }
        }
        if (this.$store.getters.getUserDDB.type != "USER") {
          collaborations.unshift({
            text: "My Ranch",
            value: this.$store.getters.getUserDDB.id,
          });
          permission[this.$store.getters.getUserDDB.id] = "WRITE";
        }
      }

      return [collaborations, pending, permission];
    },
    animals() {
      var selection_animals = [];
      for (const i in this.$store.getters.getSelectionAnimals) {
        selection_animals.push({
          value: this.$store.getters.getSelectionAnimals[i].id,
          text: this.$store.getters.getSelectionAnimals[i].tag_label,
          tag_color: this.$store.getters.getSelectionAnimals[i].tag_color,
          rfid_current: this.$store.getters.getSelectionAnimals[i].rfid_current,
          name: this.$store.getters.getSelectionAnimals[i].name,
          image_id: this.$store.getters.getSelectionAnimals[i].image_id,
          image_timestamp: this.$store.getters.getSelectionAnimals[i].image_timestamp,
          customer_id: this.$store.getters.getSelectionAnimals[i].customer_id
        });
      }
      return selection_animals;
    },

    date() {
      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      today = mm + "-" + dd + "-" + yyyy;
      return today;
    },

    weatherX() {
      return {
        currentWeather: this.$store.state.w.currentWeather,
        currentTemp: this.$store.state.w.currentTemp,
        feelsLike: this.$store.state.w.feelsLike,
        minTemp: this.$store.state.w.minTemp,
        maxTemp: this.$store.state.w.maxTemp,
        pressure: this.$store.state.w.pressure,
        humidity: this.$store.state.w.humidity,
        uv: this.$store.state.w.UV,
        overcast: this.$store.state.w.overcast,
        sunrise: this.$store.state.w.sunrise,
        sunset: this.$store.state.w.sunset,
        clouds: this.$store.state.w.clouds,
        visibility: this.$store.state.w.visibility,
        wind_speed: this.$store.state.w.wind_speed,
        wind_degree: this.$store.state.w.wind_degree,
      };
    },
    user() {
      return this.$store.getters.getUser;
    },
    customer_picture() {
      return (
        process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE +
        this.$store.getters.getUser.username +
        "/profile/" +
        this.$store.getters.getUser.username +
        "_300x300.jpg"
      );
    },
  },
  methods: {
    focus_animal_select() {
      this.selection_query_params = {
        search: this.selection_query_params.search,
        active: true
      };
    },
    has_collab(id) {
      for (const i in this.collaborations[0]) {
        if (this.collaborations[0][i].value == id) {
          return true;
        }
      }
      return false;
    },
    return_url() {
      this.dummy_nums = String(Math.floor(Math.random() * 9999999));
    },
    open_tutorial() {
      eventBus.$emit("open_tutorial");
    },
    //  open_version() {
    //   eventBus.$emit("open_version");
    // },
    cropped_url(id, timestamp, customer_id, type) {
      let ymd = moment(timestamp).format("YYYY/MM/DD")
      let total_date = moment(timestamp).format(
        "YYYY-MM-DD_HH-mm-ss"
      );
      return `${process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE}${customer_id}/animals/images/${ymd}/${id}_${total_date}_${type}.jpg`;
    },
    miniSideNav() {
      eventBus.$emit("mini_sidenav");
    },
    ...mapActions({
      GET_WEATHER_DATA: "GET_WEATHER_DATA",
    }),
    // turn_notifications() {
    //   eventBus.$emit("turn_onoff");
    // },
    async logOut() {
      await this.$store.dispatch("logout");
      this.$store.state.button = true;
      console.clear();
      localStorage.clear();
      this.$router.push("/login");
      //this.$router.go();
    },
  },
  mounted() {
    this.return_url();
    eventBus.$on("update_navbar_img", () => this.return_url());
    if (this.collaborations[0].length) {
      if (localStorage.getItem("collaboration")) {
        if (this.has_collab(localStorage.getItem("collaboration"))) {
          this.chosen_collaboration = localStorage.getItem("collaboration");
        } else {
          this.chosen_collaboration = this.$store.getters.getUserDDB.id;
        }
      } else if (this.$store.getters.getUserDDB.type != "USER") {
        this.chosen_collaboration = this.$store.getters.getUserDDB.id;
      } else {
        this.chosen_collaboration = this.collaborations[0][0].value;
      }
    }
  },

  watch: {

    selection_query_params: {
      handler() {
        if (this.selection_query_params.active) {
        this.$store.dispatch(
          "DDB_GET_SELECTION_ANIMALS",
          this.selection_query_params
        );
      }
      },
      deep: true,
    },
    collaborations() {
      if (this.collaborations[0].length && !this.chosen_collaboration) {
        if (
          localStorage.getItem("collaboration") &&
          this.has_collab(localStorage.getItem("collaboration"))
        ) {
          this.chosen_collaboration = localStorage.getItem("collaboration");
        } else {
          this.chosen_collaboration = this.collaborations[0][0].value;
        }
      }
    },

    chosen_collaboration(new_val) {
      this.$store.dispatch("DDB_GET_CUSTOMER_ID", {
        id: new_val,
        permission: this.collaborations[2][new_val],
      });
      localStorage.setItem("collaboration", new_val);
    },
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="css">
#navbar_drawer {
  z-index: 11;
  max-width: 100vw;
}
.search_rfid {
  color: "#0090a4";
}
.search_link {
  text-decoration: none;
}

.search_row {
  padding-left: 4px;
}
@media only screen and (max-width: 1400px) {
  .weather_icon {
    display: none !important;
  }
}
@media only screen and (max-width: 600px) {
  #notification_btn {
    margin-right: 12px;
  }
}
#navbar_search {
  height: 100%;
  margin-top: 8px;
  width: 274px;
}
#navbar_search > .v-input > .v-input__control > .v-input__slot {
  height: 36px !important;
  min-height: 0px !important;
  margin-bottom: 0px;
}

#navbar_search
  > .v-input
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > div {
  height: 36px !important;
  min-height: 0px !important;
  margin-bottom: 0px;
  margin-top: 7px;
}

.theme--light.v-divider {
  border-color: rgba(0, 0, 0, 1);
}
.theme--dark.v-divider {
  border-color: white;
}
#account {
  margin-left: 29px;
}
#notifications {
  margin-left: 29px;
}
.weather {
  margin-left: 8px;
}
#weather-windy {
  font-weight: bold;
  margin-left: 8px;
  margin-right: 18px;
}
.account {
  margin-top: 0px;

  display: block;
}
#date {
  font-weight: bold;
  margin-top: 29px;
  margin-left: 12px;
}
</style>