<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    min-width="500"
    id="main_dialog"
    v-model="feedback_dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-list-item-avatar
        v-bind="attrs"
        id="feedback_btn"
        text
        rounded
        v-on="on"
        @mousedown="reset()"
        color="oc_dark_grey"
        class="rounded-lg sidenav_icon_avatar"
      >
        <v-icon color="white">mdi-chat-processing-outline</v-icon>
      </v-list-item-avatar> -->
      <v-list-item-icon>
        <v-icon color="primary">mdi-chat-processing-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-title
        v-bind="attrs"
        id="feedback_btn"
        text
        rounded
        v-on="on"
        @mousedown="reset()"
        data-cy="btn_side_navbar_feedback"
        >Feedback</v-list-item-title
      >
      <!-- <v-btn
          class="mx-2"
          v-bind="attrs"
          v-on="on"
          id="feedback_btn"
          @mousedown="reset()"
          elevation="0"
          fab
          dark
          medium
          color="primary"
        >
          <v-icon dark> mdi-chat-processing-outline </v-icon>
        </v-btn> -->
    </template>
    <template v-slot:default="dialog">
      <v-card id="feedback_card">
        <v-card-text class="feedback_card_bold card_body">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">
                {{ card_title }}
              </h2>
              <v-spacer></v-spacer>
              <v-btn icon @click="feedback_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >
          <v-container v-if="submitted" class="mb-n4" style="height: 160px">
            <h1
              style="
                color: #0090a4;
                float: left;
                margin-right: 40px;
                margin-top: 60px;
              "
            >
              Thank You for Reaching Out
            </h1>
            <v-img
              style="margin-left: 20px"
              src="@/assets/BETSY_wave_smile.png"
              width="98"
            ></v-img>
          </v-container>

          <div v-if="!submitted">
            <div id="subjectselector">
              <pretty-radio
                class="p-icon p-round under_rb"
                v-model="fb_subject"
                value="NEW_FEATURE"
                name="subject"
                color="primary-o"
                ><i slot="extra" class="icon mdi mdi-check"></i>
                <div class="radiolabel">
                  <span class="under radiobutton_title"
                    >New Feature Request</span
                  ><span class="under radiobutton_description"
                    >I would like to suggest a new idea for BETSY to
                    monitor.</span
                  >
                </div></pretty-radio
              >
              <pretty-radio
                class="p-icon p-round under_rb"
                v-model="fb_subject"
                value="FEEDBACK"
                name="subject"
                color="primary-o"
                ><i slot="extra" class="icon mdi mdi-check"></i>
                <div class="radiolabel">
                  <span class="under radiobutton_title">Feedback</span
                  ><span class="under radiobutton_description">
                    I like/don't like this feature of the portal.</span
                  >
                </div></pretty-radio
              >
              <pretty-radio
                class="p-icon p-round under_rb"
                v-model="fb_subject"
                value="PROBLEM"
                name="subject"
                color="primary-o"
                ><i slot="extra" class="icon mdi mdi-check"></i>
                <div class="radiolabel">
                  <span class="under radiobutton_title">Problem</span
                  ><span class="under radiobutton_description"
                    >I am having challenges with something and need some
                    help.</span
                  >
                </div></pretty-radio
              >
            </div>

            <v-textarea
              label="Tell Us More"
              v-model="fb_description"
              :rounded="true"
              id="feedback_text_area"
              outlined
              auto-grow
              value=""
            ></v-textarea>
          </div>

          <div id="submitbutton" v-if="!submitted">
            <v-btn class="mr-2" @click="dialog.value = false">Cancel</v-btn>
            <v-btn
              color="primary"
              :disabled="fb_subject == null || fb_description.length < 1"
              @click="
                if (fb_description != '' && fb_subject != null) {
                  submit();
                }
              "
              >Submit</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import PrettyRadio from "pretty-checkbox-vue/radio";
import version from "../data/version.json";
import { eventBus } from "../main.js";
import moment from 'moment';
import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";

export default {
  components: { PrettyRadio },
  data() {
    return {
      version: version,
      fb_subject: "FEEDBACK",
      fb_description: "",
      submitted: false,
      card_title: "Feedback",
      feedback_dialog: false,
    };
  },
  methods: {
    reset() {
      this.submitted = false;
      this.card_title = "Feedback";
    },

    async submit() {

      const response = await axios.post(
        this.$store.getters.getHasuraParamsWrite.url,
        {
          query: hasura_mutations.insert_feedback_one,
          variables: {
            object1: {
              customer_id: this.user.username,
              datetime: moment().format("YYYY-MM-DD hh:mm:ss"),
              type: this.fb_subject,
              message: this.fb_description,
            },
          },
        },
        {
          headers: this.$store.getters.getHasuraParamsWrite.headers,
        }
      );
      if (response.errors || response.data.errors) {
        eventBus.$emit("add_button_alert", {
          msg: `Error sending the feedback`,
          err: true,
        });
        return false
      }

      this.fb_subject = null;
        this.fb_description = "";
        this.card_title = "";
        this.submitted = true;
        var _this = this;
        setTimeout(function () {
          _this.feedback_dialog = false;
        }, 4000);
    },

  },
  watch: {},
  created() {
    // eventBus.$on("indent_fb1", (indent) => {
    //   if (indent) {
    //     document.getElementById("feedback_btn").style.right = "380px"
    //   } else {
    //     document.getElementById("feedback_btn").style.right = "0.5%"
    //   }
    // });
  },
  mounted() {
    // if(window.location.href.split("/")[3] != "cameras") {
    //   document.getElementById("feedback_btn").style.right = "380px"
    // } else {
    //   document.getElementById("feedback_btn").style.right = "0.5%"
    // }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>
<style lang="css" scoped>
/* #bottom {
  position: absolute;
  bottom: 0;
} */
#feedback_area > div > .v-input {
  border-radius: 5px !important;
}
#ty_div {
  margin-top: 46px;
  text-align: center;
  margin-bottom: 36px;
  cursor: pointer;
}
#submitbutton {
  text-transform: capitalize;
  float: right;
  margin-top: 22px;
  margin-bottom: 22px;
}
.radiolabel {
  margin-top: -7px;
}
.v-dialog {
  max-width: 30% !important;
}
.v-textarea {
  border-radius: 0px !important;
}
#description_ta {
  font-weight: normal !important;
}
#main_dialog {
  overflow-y: hidden !important;
}
.under {
  display: block;
  clear: both !important;
}
.under_rb {
  margin-bottom: 24px;
  display: block;
  clear: both !important;
  font-size: 26px;
}
/* #feedback_btn {
  position: fixed;
  left: 14px;
  width: 200px !important;
  bottom: 1.5%;
  overflow: hidden;
  z-index: 8 !important;
} */
/* #feedback_btn::before {
  border-radius: 8px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 8px;
  
}

#feedback_btn > .v-ripple__container {
  border-radius: 8px !important;
  margin-top: 3px !important;
  height: 50px;
  width: 194px;
  padding-left: 24px !important;
  margin-left: 24px !important;
}*/
.card_toolbar {
  position: relative;
  box-shadow: none;
  overflow: hidden;
}
#close_card {
  position: absolute;
  right: 16px;
  font-size: 34px;
}
#talabel {
  font-weight: normal;
}
.radiobutton_title {
  font-weight: 500 !important;
  margin-bottom: 2px;
  font-size: 18px;
  color: #00596c;
}
#subjectselector {
  padding-top: 36px;
}
.radiobutton_description {
  padding-top: 2px;
  font-size: 14px;
}
.feedback_card_bold {
  font-weight: bold !important;
}
#feedback_card {
  overflow: hidden;
}
</style>



