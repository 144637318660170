<template>
  <v-menu
    nudge-top="9"
    nudge-right="12"
    :close-on-content-click="false"
    open-on-click
    left
    offset-overflow
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        ref="input_date"
        type="tel"
        :placeholder="input_mask"
        :value="input_value"
        @input.native="onInput"
        @input="submitValue"
        outlined
        :dense="!thick"
        :label="label"
        :rules="[validateDate]"
        ><template v-slot:append>
          <v-icon
            @click="input_value ? (input_value = '') : ''"
            v-bind="attrs"
            v-on="on"
            >mdi-calendar</v-icon
          ></template
        >
      </v-text-field>
    </template>

    <v-card>
      <v-date-picker
              @input="submitValue"

        v-model="input_value"
        flat
        no-title
        event-color="#ff9900"
      ></v-date-picker>
    </v-card>
  </v-menu>
</template>
<script>
import { validateDate } from "@/utilities/ValidationRules";
export default {
  props: ["value", "label", "thick"],
  data() {
    return {
      input_value: "",
      focused: false,
      hover: false,
      input_mask: "____-__-__",
      indexes: [],
    };
  },
  created() {
    this.input_value = this.applyMask(this.value, true);
  },
  watch: {
    value() {
      var value = this.applyMask(this.value, false);
      if (this.input_value != value) {
        this.input_value = value;
      }
    },
  },
  methods: {
    validateDate,
    clear() {
      this.input_value = null;
    },
    submitValue(v) {
      this.input_value = this.applyMask(v, true);
      if (this.checkFullyMasked(this.input_value)) {
        // console.log('locked');
        this.$refs.input_date.$el.getElementsByTagName("input")[0].maxLength =
          this.input_mask.length;
      } else {
        this.$refs.input_date.$el.getElementsByTagName("input")[0].maxLength =
          this.input_mask.length + 1;
      }
      if (this.input_value == "") {
        this.$emit("input", "");
      } else {
        this.$emit("input", this.input_value);
      }
    },
    checkFullyMasked(v) {
      return (
        v &&
        v.length == this.input_mask.length &&
        v.replace(/[^-]/gm, "").length <= 0
      );
    },

    getSelectedCharacterLength() {
      if (!this.$refs.input_date) {
        return 0;
      }
      var selectionStart =
        this.$refs.input_date.$el.getElementsByTagName("input")[0]
          .selectionStart;
      var value =
        this.$refs.input_date.$el.getElementsByTagName("input")[0].value;
      value = value.substring(0, selectionStart).replace(/[^0-9+]/gm, "");
      return value.length;
    },
    applyMask(value, moving_caret) {
      if (!value) {
        return null;
      }
      var maxLength = this.input_mask.replace(/[^_]/gm, "").length;
      value = value.replace(/[^0-9+]/gm, "");
      value = value.substring(0, Math.min(value.length, maxLength));
      var result = "";
      var cursor = 0;
      var selectionLength = this.getSelectedCharacterLength();
      for (let i = 0; i < this.input_mask.length; i++) {
        if (value.length > 0 && selectionLength > 0) {
          cursor++;
        }
        if (this.input_mask.charAt(i) == "_" && value.length > 0) {
          result += value.charAt(0);
          value = value.substring(1, value.length);
          selectionLength -= 1;
        } else {
          result += this.input_mask.charAt(i);
        }
      }
      if (result == this.input_mask) {
        result = "";
        cursor = 0;
      }

      if (moving_caret) {
        this.$nextTick(() => {
          this.$refs.input_date.$el
            .getElementsByTagName("input")[0]
            .setSelectionRange(cursor, cursor);
        });
      }
      return result;
    },
    onInput(event) {
      var maxLength = this.input_mask.length;
      event.target.value = event.target.value.substring(
        0,
        Math.min(event.target.value.length, maxLength)
      );
      event.target.value = this.applyMask(
        this.unmaskedValue(event.target.value),
        false
      );
      if (event.inputType == "insertFromPaste") {
        this.input_value = event.target.value;
        this.$emit("input", this.unmaskedValue(this.input_value));
      }
      this.$forceUpdate();
    },
    unmaskedValue(v) {
      if (v == this.input_mask || v == "" || v == null) {
        return null;
      }
      var value = `${v.replace(/[^0-9]/gm, "")}`;

      return value;
    },
  },
};
</script>