<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          large
          class="mr-2"
        >
          mdi-plus-circle
        </v-icon>
      </template>
      <v-list>
        <v-list-item
          @click="dialog_calendar_event = true"
          :disabled="!(allowed_operations == 'WRITE')"
        >
          <v-list-item-icon class="mr-2"
            ><v-icon color="primary">mdi-calendar</v-icon></v-list-item-icon
          >
          <v-list-item-title>Event</v-list-item-title>
        </v-list-item>

        <v-tooltip bottom v-if="sites && sites.length < 1">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon class="mr-2"
                ><v-icon color="oc_grey">mdi-cow</v-icon></v-list-item-icon
              >
              <v-list-item-title>Animal</v-list-item-title>
            </v-list-item>
          </template>
          <span>Create a site first in order to add animals</span>
        </v-tooltip>

        <v-list-item
          v-else
          @click="add_livestock"
          :disabled="
            !(
              allowed_operations == 'WRITE'
            )
          "
        >
          <v-list-item-icon class="mr-2"
            ><v-icon color="primary">mdi-cow</v-icon></v-list-item-icon
          >
          <v-list-item-title>Animal</v-list-item-title>
        </v-list-item>

        <v-list-item
          @click="add_treatment"
          :disabled="
            !(
              allowed_operations == 'WRITE'
            )
          "
        >
          <v-list-item-icon class="mr-2"
            ><v-icon color="primary">mdi-needle</v-icon></v-list-item-icon
          >
          <v-list-item-title>Treatment</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="dialog_calendar_event"
      max-width="900px"
      min-width="860px"
    >
      <v-card>
        <v-card-text class="pb-0">
          <v-row
            ><v-toolbar elevation="0"
              ><h2 style="color: #0090a4" data-cy="text_add_animal">
                Add Calendar Event
              </h2>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog_calendar_event = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >

          <v-row>
            <v-col cols="12" sm="7" md="7">
              <v-text-field
                outlined
                dense
                v-model="create_item_calendar_event.title"
                label="Title*"
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="5" md="5">
              <v-select
                outlined
                dense
                :items="enums.event_type"
                placeholder="All"
                v-model="create_item_calendar_event.type"
                label="Type"
              ></v-select
            ></v-col>

            <v-col cols="6" sm="3" md="3">
              <v-menu
                nudge-top="9"
                nudge-right="12"
                :close-on-content-click="false"
                open-on-click
                left
                offset-overflow
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    outlined
                    dense
                    clearable
                    v-model="create_item_calendar_event.date_start"
                    label="Start Date*"
                  >
                    <template v-slot:append>
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          !match_date(create_item_calendar_event.date_start)
                        "
                        >mdi-calendar</v-icon
                      >
                    </template>
                  </v-text-field>
                </template>

                <v-card>
                  <v-row>
                    <v-col>
                      <v-date-picker
                        v-if="match_date(create_item_calendar_event.date_start)"
                        v-model="create_item_calendar_event.date_start"
                        flat
                        no-title
                        event-color="#ff9900"
                      ></v-date-picker>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>
            </v-col>

            <v-col cols="6" sm="3" md="3">
              <v-menu
                nudge-top="9"
                nudge-right="12"
                :close-on-content-click="false"
                open-on-click
                left
                offset-overflow
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    outlined
                    dense
                    :disabled="
                      !create_item_calendar_event.date_start ||
                      !match_date(create_item_calendar_event.date_start)
                    "
                    clearable
                    v-model="create_item_calendar_event.time_start"
                    label="Start Time"
                  >
                    <template v-slot:append>
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          !create_item_calendar_event.date_start ||
                          !match_date(create_item_calendar_event.date_start) ||
                          !match_time(create_item_calendar_event.time_start)
                        "
                        >mdi-clock-outline</v-icon
                      >
                    </template>
                  </v-text-field>
                </template>

                <v-card>
                  <v-row>
                    <v-col>
                      <v-time-picker
                        v-model="create_item_calendar_event.time_start"
                        flat
                      ></v-time-picker>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <v-menu
                nudge-top="9"
                nudge-right="12"
                :close-on-content-click="false"
                open-on-click
                left
                offset-overflow
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    outlined
                    dense
                    clearable
                    v-model="create_item_calendar_event.date_end"
                    label="End Date*"
                  >
                    <template v-slot:append>
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          !match_date(create_item_calendar_event.date_end)
                        "
                        >mdi-calendar</v-icon
                      >
                    </template>
                  </v-text-field>
                </template>

                <v-card>
                  <v-row>
                    <v-col>
                      <v-date-picker
                        v-if="match_date(create_item_calendar_event.date_end)"
                        v-model="create_item_calendar_event.date_end"
                        flat
                        no-title
                        event-color="#ff9900"
                      ></v-date-picker>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <v-menu
                nudge-top="9"
                nudge-right="12"
                :close-on-content-click="false"
                open-on-click
                left
                offset-overflow
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    outlined
                    dense
                    :disabled="
                      !create_item_calendar_event.date_end ||
                      !match_date(create_item_calendar_event.date_end)
                    "
                    clearable
                    v-model="create_item_calendar_event.time_end"
                    label="End Time"
                  >
                    <template v-slot:append>
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          !create_item_calendar_event.date_end ||
                          !match_date(create_item_calendar_event.date_end) ||
                          !match_time(create_item_calendar_event.time_end)
                        "
                        >mdi-clock-outline</v-icon
                      >
                    </template>
                  </v-text-field>
                </template>

                <v-card>
                  <v-row>
                    <v-col>
                      <v-time-picker
                        v-model="create_item_calendar_event.time_end"
                        flat
                      ></v-time-picker>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-select
                outlined
                dense
                v-model="create_item_calendar_event.herd_id"
                :items="herds"
                label="Herd"
              ></v-select> </v-col
            ><v-col cols="6">
              <v-select
                outlined
                dense
                v-model="create_item_calendar_event.zone_id"
                :items="zones"
                label="Zone"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-textarea
                outlined
                dense
                v-model="create_item_calendar_event.description"
                label="Description"
                height="120px"
                auto-grow
              ></v-textarea
            ></v-col>

            <v-col cols="12"
              ><v-btn-toggle
                rounded
                v-model="create_item_calendar_event.color"
                dense
              >
                <v-btn v-for="(col, index) in event_colors" :key="index">
                  <v-icon :color="col[0]">mdi-circle</v-icon>
                </v-btn>
              </v-btn-toggle></v-col
            >
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-2 pt-2 mr-2">
          <v-row style="width: 100%">
            <v-col class="text-right">
              <div>
                <v-btn
                  color="gray"
                  width="120"
                  @click="close_calendar_event"
                  class="mr-4"
                >
                  Cancel
                </v-btn>
                <v-btn
                  data-cy="btn_create_weight_save"
                  style="float: right"
                  color="primary"
                  width="120"
                  :disabled="
                    !create_item_calendar_event.title ||
                    !create_item_calendar_event.date_start
                  "
                  @click="create_new_record_calendar_event"
                >
                  Save
                </v-btn>
              </div></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <add-animal></add-animal>
    <add-treatment></add-treatment>
  </div>
</template>

<script>
import { validateDate, validateIsNumber } from "@/utilities/ValidationRules";
import moment from "moment";
import { eventBus } from "../main.js";

import AddTreatment from "@/components/add_treatments.vue";
import AddAnimal from "@/components/add_animals.vue";

import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";
import * as hasura_queries from "../graphql_hasura/queries";

import { v4 as uuidv4 } from "uuid";

export default {
  components: { AddTreatment, AddAnimal },
  data() {
    return {
      event_colors: [
        ["oc_blue", "BLUE"],
        ["oc_mint", "MINT"],
        ["oc_green", "GREEN"],
        ["oc_indigo", "INDIGO"],
        ["oc_purple", "PURPLE"],
        ["oc_pink", "PINK"],
        ["oc_red", "RED"],
        ["oc_orange", "ORANGE"],
        ["oc_yellow", "YELLOW"],
      ],

      form_params_livestock: {
        select_species: "CATTLE",
        verified_species: true,
        expand_tag: false,
      },

      dialog_treatment: false,
      dialog_measurement: false,
      dialog_calendar_event: false,
      create_new_tab_livestock: 0,
      create_item_treatment: { treatment_administered_drugs: [{}] },
      create_item_measurement: {},
      create_item_calendar_event: {},
      registration_date: "",
      opened_event: false,

      tab_type: 0,
      radio_btn_types_model: 0,
      text_field_labels: {
        WEIGHT_MATURE: "Mature Weight (lbs)*",
        WEIGHT_YEARLING: "Yearling Weight (lbs)*",
        WEIGHT_WEANING: "Weaning Weight (lbs)*",
        WEIGHT_BIRTH: "Birth Weight (lbs)*",
        HEIGHT: "Height (in)*",
        PELVIC_HEIGHT: "Pelvic Height (in)*",
        PELVIC_WIDTH: "Pelvic Width (in)*",
        SCROTUM_CIRCUMFERENCE: "Scrotum Circumference (in)*",
        SPERM_MOTILITY: "Sperm Motility (%)*",
        SPERM_MORPHOLOGY: "Sperm Morphology (%)*",
        OTHER: "Other",
      },
      radio_btn_types: [
        [
          {
            label: "Mature",
            text_field_label: "Mature Weight (lbs)*",
            value: "WEIGHT_MATURE",
          },
          {
            label: "Yearling",
            text_field_label: "Yearling Weight (lbs)*",
            value: "WEIGHT_YEARLING",
          },
          {
            label: "Weaning",
            text_field_label: "Weaning Weight (lbs)*",
            value: "WEIGHT_WEANING",
          },
          {
            label: "Birth",
            text_field_label: "Birth Weight (lbs)*",
            value: "WEIGHT_BIRTH",
          },
        ],
        [
          {
            label: "Height",
            text_field_label: "Height (in)*",
            value: "HEIGHT",
          },
        ],
        [
          {
            label: "Pelvic Height",
            text_field_label: "Pelvic Height (in)*",
            value: "PELVIC_HEIGHT",
          },
          {
            label: "Pelvic Width",
            text_field_label: "Pelvic Width (in)*",
            value: "PELVIC_WIDTH",
          },
          {
            label: "Scrotum Circumference",
            text_field_label: "Scrotum Circumference (in)*",
            value: "SCROTUM_CIRCUMFERENCE",
          },
          {
            label: "Sperm Motility",
            text_field_label: "Sperm Motility (%)*",
            value: "SPERM_MOTILITY",
          },
          {
            label: "Sperm Morphology",
            text_field_label: "Sperm Morphology (%)*",
            value: "SPERM_MORPHOLOGY",
          },
        ],
        [
          {
            label: "OTHER",
            text_field_label: "Other",
            value: "OTHER",
          },
        ],
      ],

      measurement_units: {
        KG: "kg",
        LB: "lb",
        CM: "cm",
        IN: "in",
        COUNT: "Count",
        PERCENT: "%",
        TOTAL: "Total",
        OTHER: "Other",
      },
    };
  },
  methods: {
    validateDate,
    validateIsNumber,
    async open_event(id) {
      const colors = {
        BLUE: 0,
        MINT: 1,
        GREEN: 2,
        INDIGO: 3,
        PURPLE: 4,
        PINK: 5,
        RED: 6,
        ORANGE: 7,
        YELLOW: 8,
      };
      this.opened_event = (
        await axios.post(
          this.$store.getters.getHasuraParams.url,
          {
            query: hasura_queries.event_by_pk,
            variables: {
              id: id,
            },
          },
          {
            headers: this.$store.getters.getHasuraParams.headers,
          }
        )
      ).data.data.event_by_pk;

      this.create_item_calendar_event = {
        title: this.opened_event.title,
        type: this.opened_event.type,
        description: this.opened_event.description,
        date_start: this.opened_event.datetime_start.split("T")[0],
        time_start: this.opened_event.datetime_start.split("T")[1].slice(0, 5),
        date_end: this.opened_event.datetime_end
          ? this.opened_event.datetime_end.split("T")[0]
          : "",
        time_end: this.opened_event.datetime_end
          ? this.opened_event.datetime_end.split("T")[1].slice(0, 5)
          : "",
        color: colors[this.opened_event.color],
      };

      if (this.opened_event.herd_id) {
        this.create_item_calendar_event.herd_id = this.opened_event.herd_id;
      }
      if (this.opened_event.zone_id) {
        this.create_item_calendar_event.zone_id = this.opened_event.zone_id;
      }
      this.dialog_calendar_event = true;
    },
    match_date(str) {
      if (str) {
        var m = str.match(
          /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/
        );
        if (m) {
          return true;
        }
        return false;
      }
      return true;
    },
    match_time(str) {
      if (str) {
        var m = str.match(/^([0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
        if (m) {
          return true;
        }
        return false;
      }
      return true;
    },

    add_livestock() {
      eventBus.$emit("add_animal_open");
    },

    add_treatment() {
      eventBus.$emit("add_treatment_open");
    },

    close_calendar_event() {
      this.dialog_calendar_event = false;

      this.$nextTick(() => {
        this.create_item_calendar_event = {};
        this.opened_event = false;
      });
    },
    async create_new_record_calendar_event() {
      this.create_item_calendar_event.id = uuidv4();
      this.create_item_calendar_event.customer_id =
        this.$store.getters.getCustomerID;

      this.create_item_calendar_event.datetime_start = moment(
        this.create_item_calendar_event.date_start +
          (this.create_item_calendar_event.time_start
            ? this.create_item_calendar_event.time_start
            : ""),
        this.create_item_calendar_event.time_start
          ? "YYYY-MM-DDHH:mm"
          : '"YYYY-MM-DD"'
      ).format("YYYY-MM-DDTHH:mm:ss.sssZ");

      if (this.create_item_calendar_event.date_end) {
        this.create_item_calendar_event.datetime_end = moment(
          this.create_item_calendar_event.date_end +
            (this.create_item_calendar_event.time_end
              ? this.create_item_calendar_event.time_end
              : ""),
          this.create_item_calendar_event.time_end
            ? "YYYY-MM-DDHH:mm"
            : '"YYYY-MM-DD"'
        ).format("YYYY-MM-DDTHH:mm:ss.sssZ");
      }

      delete this.create_item_calendar_event.date_start;
      delete this.create_item_calendar_event.time_start;
      delete this.create_item_calendar_event.date_end;
      delete this.create_item_calendar_event.time_end;

      this.create_item_calendar_event.color = this.event_colors[
        this.create_item_calendar_event.color
      ]
        ? this.event_colors[this.create_item_calendar_event.color][1]
        : "ORANGE";

      for (const n in this.create_item_calendar_event) {
        if (typeof this.create_item_calendar_event[n] == "string") {
          this.create_item_calendar_event[n] =
            this.create_item_calendar_event[n].trim();
          if (this.create_item_calendar_event[n].length == 0) {
            delete this.create_item_calendar_event[n];
          }
        }
      }
      try {
        if (this.opened_event) {
          this.create_item_calendar_event.id =  this.opened_event.id
        }
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.insert_event_one,
            variables: {
              object1: this.create_item_calendar_event,
              update_columns: [
                "color",
                "datetime_end",
                "datetime_start",
                "description",
                "herd_id",
                "title",
                "type",
                "zone_id"
              ],
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        console.log(response);
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        this.dialog_calendar_event = false;

        eventBus.$emit("fetch_calendar_events");
        this.create_item_calendar_event = {};
        eventBus.$emit("add_button_alert", {
          msg: "Successfully created the event",
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: "Error creating the event",
          err: true,
        });
      }
    },
  },

  computed: {
    enums() {
      var enums = {};
      enums.tag_color = this.$store.getters.getEnums.tag_color
        ? this.$store.getters.getEnums.tag_color
        : [];
      enums.birth_ease = this.$store.getters.getEnums.birth_ease
        ? this.$store.getters.getEnums.birth_ease
        : [];
      enums.nurse_info = this.$store.getters.getEnums.nurse_info
        ? this.$store.getters.getEnums.nurse_info
        : [];
      enums.dam_status = this.$store.getters.getEnums.dam_status
        ? this.$store.getters.getEnums.dam_status
        : [];
      enums.treatment_type = this.$store.getters.getEnums.treatment_type
        ? this.$store.getters.getEnums.treatment_type
        : [];
      enums.drug_administration_type = this.$store.getters.getEnums
        .drug_administration_type
        ? this.$store.getters.getEnums.drug_administration_type
        : [];
      enums.drug_type = this.$store.getters.getEnums.drug_type
        ? this.$store.getters.getEnums.drug_type
        : [];
      enums.event_type = this.$store.getters.getEnums.event_type
        ? this.$store.getters.getEnums.event_type
        : [];
      return enums;
    },
    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },
    sites() {
      var sites = [];
      for (const i in this.$store.getters.getSites) {
        sites.push({
          text: this.$store.getters.getSites[i].site_name,
          value: this.$store.getters.getSites[i].id,
        });
      }
      return sites;
    },

    herds() {
      var herds_only = [];
      for (const i in this.$store.getters.getHerds) {
        herds_only.push({
          text: this.$store.getters.getHerds[i].name,
          value: this.$store.getters.getHerds[i].id,
        });
      }

      return herds_only;
    },
    zones() {
      var herds = [];

      for (const i in this.$store.getters.getZones) {
        herds.push({
          text: this.$store.getters.getZones[i].name,
          value: this.$store.getters.getZones[i].id,
        });
      }

      return herds;
    },
  },
  created() {
    if (!this.$store.getters.getHerds.length) {
      this.$store.dispatch("DDB_GET_HERDS");
    }

    eventBus.$on("open_event", (e) => this.open_event(e));
  },
  watch: {
    tab_type() {
      this.radio_btn_types_model = this.radio_btn_types[this.tab_type][0].value;
    },

    dialog_treatment() {
      if (this.dialog_treatment == false) {
        this.close_treatment();
      }
    },
    dialog_measurement() {
      if (this.dialog_measurement == false) {
        this.close_measurement();
      } else {
        this.radio_btn_types_model = this.radio_btn_types[0][0].value;
      }
    },
    dialog_calendar_event() {
      if (this.dialog_calendar_event == false) {
        this.close_calendar_event();
      }
    },
  },
};
</script>
<style lang="css" scoped>
.an_err {
  position: relative;
}
</style>