<template>
  <div>
    <v-navigation-drawer
      app
      v-model="drawer"
      mobile-breakpoint="720"
      class="app--drawer"
      width="240"
      mini-variant-width="66"
      id="sidebar_drawer"
      :mini-variant="mini"
    >
      <v-list-item>
        <router-link to="/">
          <v-container>
            <v-img
              v-if="!$vuetify.theme.dark && !mini"
              class="logo ml-4"
              src="@/assets/LogoV2-Black_ClearBknd.png"
              width="145"
              height="60"
            ></v-img>
            <v-img
              v-if="!$vuetify.theme.dark && mini"
              class="logo"
              src="@/assets/favicon.png"
              width="40"
              height="40"
            ></v-img>
            <v-img
              v-if="$vuetify.theme.dark && !mini"
              class="logo ml-4"
              src="@/assets/LogoV2-White_ClearBknd.png"
              width="145"
              height="60"
            ></v-img>
            <v-img
              v-if="$vuetify.theme.dark && mini"
              class="logo"
              src="@/assets/favicon_white.png"
              width="40"
              height="40"
            ></v-img>
          </v-container>
        </router-link>

        <v-list-item-title></v-list-item-title>
        <!-- <v-btn icon @click.stop="mini = !mini"> -->
      </v-list-item>

      <v-list :id="mini ? 'sidenav_list_mini' : 'sidenav_list'">
        <!-- <v-divider /> -->

        <!--    <v-list-item v-if="mini">
          <v-icon icon @click="miniStorage">mdi-chevron-right</v-icon>
        </v-list-item> -->

        <v-list-group v-model="dashboard_group" no-action>
          <template v-slot:activator>
            <v-list-item-avatar
              @click="nav_to_tab('home')"
              :color="'primary'"
              class="rounded-lg sidenav_icon_avatar"
            >
              <v-icon color="white">mdi-home</v-icon>
            </v-list-item-avatar>

            <v-list-item-title @click="nav_to_tab('home')"
              >Dashboard</v-list-item-title
            >
          </template>

          <v-list-item
            :class="
              current_path == '' ? 'sub_list_active sub_list' : 'sub_list'
            "
            @click="nav_to_tab('home')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'primary'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-monitor-dashboard</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Overview</v-list-item-title
            >
          </v-list-item>

          <!-- <v-list-item
            :class="
              current_path == 'footageuploads'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('footageuploads')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'primary'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-cctv</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Cameras</v-list-item-title
            >
          </v-list-item> -->

          <v-list-item
            :class="
              current_path == 'animal_dashboard'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('animal_dashboard')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'primary'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-cow</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Animals</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'health_dashboard'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('health_dashboard')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'primary'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-medical-bag</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Health</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'measurements_dashboard'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('measurements_dashboard')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'primary'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-ruler</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Measurements</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'phenotype_dashboard'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('phenotype_dashboard')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'primary'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-card-bulleted-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Phenotypes</v-list-item-title
            >
          </v-list-item>
        </v-list-group>

        <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>

        <v-list-group v-model="animal_group" no-action>
          <template v-slot:activator>
            <v-list-item-avatar
              @click="nav_to_tab('animals', 'general')"
              :color="'oc_green'"
              class="rounded-lg sidenav_icon_avatar"
            >
              <v-icon color="white">mdi-cow</v-icon>
            </v-list-item-avatar>

            <v-list-item-title @click="nav_to_tab('animals', 'general')"
              >Animals</v-list-item-title
            >
          </template>

          <v-list-item
            :class="
              current_path == 'animals' && sub_path == 'general'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('animals', 'general')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_green'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-table</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >General</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'animals' && sub_path == 'history'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('animals', 'history')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_green'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-history</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >History</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'animals' && sub_path == 'pedigree'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('animals', 'pedigree')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_green'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-family-tree</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Pedigree</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'animals' && sub_path == 'measurements'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('animals', 'measurements')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_green'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-ruler</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Measurements</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'animals' && sub_path == 'phenotypes'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('animals', 'phenotypes')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_green'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-card-bulleted-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Phenotypes</v-list-item-title
            >
          </v-list-item>
        </v-list-group>

        <v-list-item text rounded to="/treatments" class="sidenav_list_item">
          <v-list-item-avatar
            :color="'oc_green'"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-needle</v-icon>
          </v-list-item-avatar>

          <v-list-item-title data-cy="btn_side_navbar_treatments"
            >Treatments</v-list-item-title
          >
        </v-list-item>
        <!-- <v-list-item text rounded to="/carcasses">
          <v-list-item-icon>
            <v-icon color="primary">mdi-food-drumstick</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Carcasses</v-list-item-title>
        </v-list-item> -->

        <!-- <v-list-item text rounded to="/herds">
          <v-list-item-icon>
            <v-icon color="primary">mdi-barn</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Herds</v-list-item-title>
        </v-list-item> -->

        <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>

        <v-list-group v-model="animal_id_group" no-action>
          <template v-slot:activator>
            <v-list-item-avatar
              @click="nav_to_tab('animal_identification', 'pending')"
              :color="'oc_indigo'"
              class="rounded-lg sidenav_icon_avatar"
            >
              <v-icon color="white">mdi-tag-multiple</v-icon>
            </v-list-item-avatar>

            <v-list-item-title
              @click="nav_to_tab('animal_identification', 'pending')"
              >Animal ID</v-list-item-title
            >
          </template>

          <v-list-item
            :class="
              current_path == 'animal_identification' && sub_path == 'pending'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('animal_identification', 'pending')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_indigo'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-minus-circle-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Pending</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'animal_identification' && sub_path == 'approved'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('animal_identification', 'approved')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_indigo'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-check-circle-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Approved</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'animal_identification' && sub_path == 'denied'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('animal_identification', 'denied')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_indigo'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-close-circle-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Denied</v-list-item-title
            >
          </v-list-item>
        </v-list-group>

        <v-list-group v-model="notification_group" no-action>
          <template v-slot:activator>
            <v-list-item-avatar
              @click="nav_to_tab('notifications', 'all')"
              :color="'oc_indigo'"
              class="rounded-lg sidenav_icon_avatar"
            >
              <v-icon color="white">mdi-bell</v-icon>
            </v-list-item-avatar>

            <v-list-item-title @click="nav_to_tab('notifications', 'all')"
              >Notifications</v-list-item-title
            >
          </template>

          <v-list-item
            :class="
              current_path == 'notifications' && sub_path == 'all'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('notifications', 'all')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_indigo'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-message-badge</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >All</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'notifications' && sub_path == 'info'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('notifications', 'info')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_indigo'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-information-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Information</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'notifications' && sub_path == 'warning'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('notifications', 'warning')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_indigo'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-shield-alert-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Warning</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'notifications' && sub_path == 'alert'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('notifications', 'alert')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_indigo'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-alert-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Alert</v-list-item-title
            >
          </v-list-item>
        </v-list-group>

        <!-- <v-list-item>
          <feedback></feedback>
        </v-list-item> -->
        <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>

        <v-list-item
          text
          rounded
          :to="'/images'"
          :value="true"
          class="sidenav_list_item"
        >
          <v-list-item-avatar
            :color="'oc_yellow'"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-image</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Images</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="setPlayback()"
          text
          rounded
          :to="'/videos/all'"
          :value="true"
          class="sidenav_list_item"
        >
          <v-list-item-avatar
            :color="'oc_yellow'"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-video-box</v-icon>
          </v-list-item-avatar>
          <v-list-item-title data-cy="btn_side_navbar_videos"
            >Videos</v-list-item-title
          >
        </v-list-item>

        <v-list-group v-model="insights_group" no-action>
          <template v-slot:activator>
            <v-list-item-avatar
              @click="nav_to_tab('analytics', 'detections')"
              :color="'oc_yellow'"
              class="rounded-lg sidenav_icon_avatar"
            >
              <v-icon color="white">mdi-poll</v-icon>
            </v-list-item-avatar>

            <v-list-item-title @click="nav_to_tab('analytics', 'detections')"
              >Insights</v-list-item-title
            >
          </template>

          <v-list-item
            :class="
              current_path == 'analytics' && sub_path == 'detections'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('analytics', 'detections')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_yellow'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-eye</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Detections</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'analytics' && sub_path == 'animals'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('analytics', 'animals')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_yellow'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-cow</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Animals</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'analytics' && sub_path == 'bodyparts'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('analytics', 'bodyparts')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_yellow'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-food-drumstick</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Bodyparts</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            :class="
              current_path == 'analytics' && sub_path == 'calving'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('analytics', 'calving')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_yellow'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-baby-carriage</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Calving</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'analytics' && sub_path == 'estrus'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('analytics', 'estrus')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_yellow'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-gender-male-female</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Estrus</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'analytics' && sub_path == 'predator'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('analytics', 'predator')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_yellow'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-dog-side</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Predator</v-list-item-title
            >
          </v-list-item>
        </v-list-group>

        <!-- <v-divider :class="(mini)  ? 'sidenav_divider_mini' : 'sidenav_divider'" :inset="inset"></v-divider> -->
        <!-- <v-list-item text rounded to="/sites-overview">
          <v-list-item-icon>
            <v-icon color="primary">mdi-barn</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sites</v-list-item-title>
        </v-list-item> -->
        <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>

        <v-list-group v-model="camera_group" no-action>
          <template v-slot:activator>
            <v-list-item-avatar
              @click="nav_to_tab('cameras', 'overview')"
              :color="'oc_orange'"
              class="rounded-lg sidenav_icon_avatar"
            >
              <v-icon color="white">mdi-cctv</v-icon>
            </v-list-item-avatar>

            <v-list-item-title @click="nav_to_tab('cameras', 'overview')"
              >Cameras</v-list-item-title
            >
          </template>

          <v-list-item
            :class="
              current_path == 'cameras' && sub_path == 'overview'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('cameras', 'overview')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_orange'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-chart-box</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Overview</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            :class="
              current_path == 'cameras' && sub_path == 'status'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('cameras', 'status')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_orange'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-table</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Status</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            :class="
              current_path == 'cameras' && sub_path == 'recent-footage'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('cameras', 'recent-footage')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_orange'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-image-area</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >Recent Footage</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            :class="
              current_path == 'cameras' && sub_path == 'by-camera'
                ? 'sub_list_active sub_list'
                : 'sub_list'
            "
            @click="nav_to_tab('cameras', 'by-camera')"
            text
            rounded
          >
            <v-list-item-avatar
              :color="'oc_orange'"
              class="rounded sub_sidenav_icon_avatar"
            >
              <v-icon color="white" size="small">mdi-webcam</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="btn_side_navbar_animals"
              >By Camera</v-list-item-title
            >
          </v-list-item>
        </v-list-group>

        <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>
        <!-- <v-list-item text rounded to="/reports">
          <v-list-item-avatar
            :color="'oc_purple'"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-chart-tree</v-icon>
          </v-list-item-avatar>
          <v-list-item-title 
            >Reports</v-list-item-title
          >
        </v-list-item> -->
        <v-list-item text rounded to="/notes" class="sidenav_list_item">
          <v-list-item-avatar
            :color="'oc_purple'"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-message-bulleted</v-icon>
          </v-list-item-avatar>
          <v-list-item-title data-cy="btn_side_navbar_notes"
            >Notes</v-list-item-title
          >
        </v-list-item>

        <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>
        <v-list-item text rounded to="/map" class="sidenav_list_item">
          <v-list-item-avatar
            :color="'oc_blue'"
            class="rounded-lg sidenav_icon_avatar"
          >
            <v-icon color="white">mdi-map</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Map</v-list-item-title>
        </v-list-item>
        <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>
        <!-- <v-list-group>
          <template v-slot:activator>
            <v-list-item-avatar color="oc_green" class="rounded-lg">
              <v-icon color="white">mdi-dots-horizontal</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="ml-n1"></v-list-item-title>
          </template>

          <v-divider class="mx-4" :inset="inset"></v-divider>

          <v-list-item text rounded>
            <v-list-item-icon>
              <v-icon color="primary">mdi-heart</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="coming_soon">Activity</v-list-item-title>
          </v-list-item>

          <v-list-item text rounded>
            <v-list-item-icon>
              <v-icon color="primary">mdi-nutrition</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="coming_soon">Nutrition</v-list-item-title>
          </v-list-item>
          <v-divider class="mx-4" :inset="inset"></v-divider>

          <v-list-item text rounded>
            <v-list-item-icon>
              <v-icon color="primary">mdi-thermometer</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="coming_soon">Health</v-list-item-title>
          </v-list-item>

          <v-list-item text rounded>
            <v-list-item-icon>
              <v-icon color="primary">mdi-weight-pound</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="coming_soon">Weight</v-list-item-title>
          </v-list-item>

          <v-list-item text rounded>
            <v-list-item-icon>
              <v-icon color="primary">mdi-molecule-co2</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="coming_soon"
              >Respiration</v-list-item-title
            >
          </v-list-item>

          <v-list-item text rounded>
            <v-list-item-icon>
              <v-icon color="primary">mdi-gauge</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="coming_soon">Stress</v-list-item-title>
          </v-list-item>

          <v-list-item text rounded>
            <v-list-item-icon>
              <v-icon color="primary">mdi-stop-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="coming_soon">Lameness</v-list-item-title>
          </v-list-item>

          <v-divider class="mx-4" :inset="inset"></v-divider>

          <v-list-item text rounded>
            <v-list-item-icon>
              <v-icon color="primary">mdi-identifier</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="coming_soon"
              >360 Live ID</v-list-item-title
            >
          </v-list-item>

          <v-divider class="mx-4" :inset="inset"></v-divider>
          <v-list-item text rounded>
            <v-list-item-icon>
              <v-icon color="primary">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="coming_soon">Users</v-list-item-title>
          </v-list-item>
          <v-list-item text rounded>
            <v-list-item-icon>
              <v-icon color="primary">mdi-mail</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="coming_soon">Help</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider class="mx-4" :inset="inset"></v-divider> -->

        <v-list-item id="theme_selection">
          <v-list-item-avatar color="oc_indigo" class="rounded-lg">
            <v-icon v-if="!$vuetify.theme.dark" dark color="white"
              >mdi-weather-night</v-icon
            >

            <v-icon v-if="$vuetify.theme.dark" dark color="white"
              >mdi-weather-night</v-icon
            >
          </v-list-item-avatar>
          <v-list-item-title
            class=""
            v-if="!$vuetify.theme.dark"
            data-cy="btn_side_navbar_dark_mode"
          >
            <span style="display: inline-block; margin-top: 10px">Dark</span>
            <v-switch
              class="mt-0 py-2"
              color="primary"
              small
              inset
              v-model="dark_mode"
              tog
              hide-details
              style="float: right"
            ></v-switch
          ></v-list-item-title>
          <v-list-item-title
            class=""
            v-if="$vuetify.theme.dark"
            data-cy="btn_side_navbar_light_mode"
          >
            <span style="display: inline-block; margin-top: 10px">Light</span>
            <v-switch
              class="mt-0 py-2"
              color="primary"
              small
              inset
              v-model="dark_mode"
              tog
              hide-details
              style="float: right"
            ></v-switch>
          </v-list-item-title>
          <!-- <v-list-item-action>
            <v-switch @click="toggleTheme"></v-switch>
          </v-list-item-action>-->
        </v-list-item>
        <!-- <v-list-item>
          <feedback></feedback>
        </v-list-item> -->
        <v-divider
          :class="mini ? 'sidenav_divider_mini' : 'sidenav_divider'"
          :inset="inset"
        ></v-divider>

        <v-list-item text rounded v-if="!mini">
          <v-list-item-content class="text-center">
            <v-list-item-title @click="open_version" style="cursor: pointer">
              <h4 class="pr-4">v {{ version["version"] }}</h4>
              <h6 class="pr-4">{{ version["last_update"] }}</h6>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!--
        <v-list-item text rounded>
          <v-list-item-icon>
            <v-icon color="gray" @click="refreshAPI">mdi-refresh</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="refreshMessage == 'Refreshed API'">
            <h3 id="refreshMessage">{{ refreshMessage }}</h3>
          </v-list-item-title>
        </v-list-item>-->

        <v-list-item text rounded v-if="!mini" style="margin-top: -14px">
          <v-list-item-content>
            <v-list-item-title> Need help? </v-list-item-title>

            <v-list-item-subtitle>
              <a
                href="mailto:info@onecup.ai"
                style="text-decoration: none; color: inherit"
                >info@onecup.ai</a
              >
            </v-list-item-subtitle>
            <v-list-item-subtitle>1-866-BETSYAI</v-list-item-subtitle>
          </v-list-item-content>
          <v-img
            style="max-width: 80px"
            src="@/assets/BETSY_wave_smile.png"
          ></v-img>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { eventBus } from "../main.js";
import version from "../data/version.json";
export default {
  components: {},
  name: "SideNav",
  props: ["sub_path"],
  data() {
    return {
      on_lf: false,
      on_pb: false,
      version: version,
      feedtype: null,
      cameras: [],
      camerasBat: [],
      inset: null,
      drawer: true,
      mini: false,
      selectedIndex: 1,
      refreshMessage: String,
      sortedCameras: null,
      lf_pb: null,
      active_cams: [],
      dark_mode: false,
      links: [
        {
          label: "DynamoDB Test",
          url: "/",
          icon: "mdi-yarn",
        },
        {
          label: "Home",
          url: "/",
          icon: "mdi-home-outline",
        },

        {
          label: "Support",
          url: "/support",
          icon: "mdi-email-outline",
        },
        {
          label: "Dashboard",
          url: "/dashboard",
          icon: "mdi-home-outline",
        },

        {
          label: "Animals",
          url: "/animals",
          icon: "mdi-cow",
        },
        {
          label: "Search",
          url: "/search",
          icon: "mdi-database",
        },
        {
          label: "RTDB",
          url: "/rtdb",
          icon: "mdi-database-settings",
        },
      ],
      selected_cameras: [],

      animal_group: false,
      animal_id_group: false,
      notification_group: false,
      insights_group: false,
      camera_group: false,
      dashboard_group: false,
    };
  },
  computed: {
    // cameras() {
    //   return this.$store.getters.getCameras;
    // },
    current_path() {
      return this.$route.path.split("/")[1].replace("-", "_");
    },

    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },
  },
  methods: {
    nav_to_tab(tab, subtab) {
      let current_path = this.$route.path.split("/")[1].replace("-", "_");
      if (current_path == tab) {
        eventBus.$emit("change_tab", subtab);
      } else {
        if (tab == "animals") {
          this.$router.push({
            name: tab,
            params: { animal_index: "all", tab: subtab },
          });
        } else if (
          tab == "animal_identification" ||
          tab == "analytics" ||
          tab == "cameras"
        ) {
          this.$router.push({
            name: tab,
            params: { tab: subtab },
          });
        } else if (tab == "notifications") {
          this.$router.push({
            name: tab,
            params: { tab: subtab, date_filter: "all" },
          });
        } else if (subtab == null) {
          this.$router.push({
            name: tab,
          });
        }
      }
    },

    open_version() {
      eventBus.$emit("open_version");
    },

    setLivefeed() {
      this.mini = false;
      localStorage.setItem("mini", this.mini.toString());
      this.feedtype = "livefeed";
      this.lf_pb = 0;
    },
    setPlayback() {
      this.feedtype = "playback";
      this.lf_pb = 1;
    },
    getOccurrence(array, value) {
      return array.filter((v) => v === value).length;
    },
    highlightCamera(camera_name) {
      try {
        setTimeout(function () {
          document.getElementById(camera_name + "_icon").style.color =
            "#ff9900";
        }, 200);
      } catch (error) {
        return false;
      }
    },
    unHighlightCamera(camera_name) {
      try {
        if (this.getOccurrence(this.active_cams, camera_name) < 1) {
          document.getElementById(camera_name + "_icon").style.color = "gray";
        }
      } catch (error) {
        return false;
      }
    },
    startDragging(camera_name, status) {
      if (status == "ONLINE" || status == "WARNING") {
        eventBus.$emit("start_dragging", camera_name);
      }
    },

    toggleTheme() {
      this.$vuetify.theme.themes.dark.anchor = "#cc9b5c";
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
      const menuPreference = localStorage.getItem("mini");
      if (menuPreference) {
        if (menuPreference === "true") {
          this.mini = true;
        } else {
          this.mini = false;
        }
      }
    },
    miniStorage() {
      if (document.getElementsByTagName("body")[0].clientWidth > 600) {
        this.mini = !this.mini;
        localStorage.setItem("mini", this.mini);
      } else {
        this.mini = false;
        this.drawer = !this.drawer;
      }
    },
    refreshAPI() {
      this.$store.dispatch("GET_WEATHER_DATA");
      this.refreshMessage = "Refreshed API";
      setTimeout(() => (this.refreshMessage = false), 2000);
    },
    changeRoute(routeName, selectedIndex) {
      const vm = this;

      vm.selectedIndex = selectedIndex;

      return vm.$router.push({ name: routeName });
    },
    set_active_cams(cams) {
      this.active_cams = cams;
    },
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
        this.dark_mode = true;
      } else {
        this.$vuetify.theme.dark = false;
        this.dark_mode = false;
      }
    }
    // else if (
    //   window.matchMedia &&
    //   window.matchMedia("(prefers-color-scheme: dark)").matches
    // ) {
    //   this.$vuetify.theme.dark = true;
    //   localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    // }

    // mini
    const menuPreference = localStorage.getItem("mini");
    if (menuPreference) {
      if (menuPreference === "true") {
        this.mini = true;
      } else {
        this.mini = false;
      }
    }
  },

  created() {
    if (this.current_path == "animals") {
      this.animal_group = true;
    } else if (this.current_path == "animal_identification") {
      this.animal_id_group = true;
    } else if (this.current_path == "notifications") {
      this.notification_group = true;
    } else if (this.current_path == "analytics") {
      this.insights_group = true;
    } else if (this.current_path == "cameras") {
      this.camera_group = true;
    } else if (
      this.current_path == "" ||
      this.current_path == "footageuploads" ||
      this.current_path == "animal_dashboard" ||
      this.current_path == "health_dashboard" ||
      this.current_path == "measurements_dashboard" ||
      this.current_path == "phenotype_dashboard"
    ) {
      this.dashboard_group = true;
    }

    if (window.innerWidth < 600) {
      this.drawer = false;
    }
    eventBus.$on("update_sidenav_cameras", (cameras) => {
      this.selected_cameras = cameras;
    });
    eventBus.$emit("turn_on_live_feed");
    eventBus.$on("mini_sidenav", () => this.miniStorage());
    eventBus.$on("active_cams", (cam) => this.set_active_cams(cam));
    if (window.location.href.split("/")[3] != "cameras") {
      if (localStorage.getItem("selectedFeedPlayback") == "0") {
        this.setLivefeed();
      } else if (localStorage.getItem("selectedFeedPlayback") == "1") {
        this.setPlayback();
      } else {
        this.setPlayback();
      }
    } else {
      if (window.location.href.split("/")[4] == "livefeed") {
        this.setLivefeed();
        this.on_lf = true;
      } else if (window.location.href.split("/")[4] == "playback") {
        this.setPlayback();
        this.on_pb = true;
      }
    }

    // this.$store
    //   .dispatch("DDB_GET_THUMBNAIL_INFO", "")
    //   .then(() => this.populateCameras());
    eventBus.$on("highlight_camera", (camera_name) => {
      this.highlightCamera(camera_name);
    });
    eventBus.$on("un_highlight_camera", (camera_name) => {
      this.unHighlightCamera(camera_name);
    });
    eventBus.$on("lf_pb_switch", (lf_pb) => {
      this.lf_pb = lf_pb;
    });
  },

  watch: {
    dark_mode() {
      if (this.dark_mode && !this.$vuetify.theme.dark) {
        this.toggleTheme();
      } else if (!this.dark_mode && this.$vuetify.theme.dark) {
        this.toggleTheme();
      }
    },
  },
};
</script>





<style lang="css">
/* #bottom {
  position: absolute;
  bottom: 0;
} 
#divider {
  height: 60px;
}*/

.sidenav_divider_mini {
  margin-left: 12px !important;
  margin-right: 12px !important;
}
.sidenav_divider {
  margin-left: 34px !important;
  margin-right: 22px !important;
}

.sub_list:hover::before {
  border-radius: 8px;
  margin-top: 6px;
  height: 36px;
  padding-left: 20px !important;
  margin-left: 65px;
  margin-right: 24px;
  width: 161px;
}

.sub_list_active::before {
  border-radius: 8px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding-left: 20px !important;
  margin-left: 65px;
  margin-right: 24px;
  width: 161px;
  opacity: 0.08;
}

.sub_list > .v-ripple__container {
  border-radius: 8px;
  margin-top: 6px;
  margin-bottom: 6px;
  height: 36px;
  padding-left: 20px !important;
  margin-left: 65px;
  margin-right: 24px;
  width: 161px;
}
.sub_list_active:focus::before {
  opacity: 0 !important;
}

.sub_list_active:hover::before {
  opacity: 0.04;
}

/* #sidenav_list > .v-list-item--active:hover::before {
  opacity: 0.12 !important;
} */
.sub_list_active {
  padding-left: 30px;
}

#sidenav_list > .sidenav_list_item::before {
  border-radius: 8px;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 20px !important;
  margin-left: 24px;
  margin-right: 24px;
  width: 212px;
}

#sidenav_list > .sidenav_list_item > .v-ripple__container {
  border-radius: 8px !important;
  margin-top: 3px !important;
  height: 50px;
  width: 202px;
  padding-left: 24px !important;
  margin-left: 24px !important;
}
#sidenav_list > .sidenav_list_item:focus::before {
  opacity: 0 !important;
}

#sidenav_list > .sidenav_list_item:hover::before {
  opacity: 0.04;
}

/* #sidenav_list > .v-list-item--active:hover::before {
  opacity: 0.12 !important;
} */
#sidenav_list .sidenav_list_item {
  padding-left: 30px;
}

#sidenav_list .v-list-group__header::before {
  border-radius: 8px;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 20px !important;
  margin-left: 24px;
  margin-right: 24px;
  width: 202px;
}

#sidenav_list .v-list-group__header > .v-ripple__container {
  border-radius: 8px !important;
  margin-top: 3px !important;
  height: 50px;
  width: 202px;
  padding-left: 24px !important;
  margin-left: 24px !important;
}
.v-list-group__header:focus::before {
  opacity: 0 !important;
}
.v-list-group--active .v-list-group__header::before {
  opacity: 0.12 !important;
}

.v-list-group__header:hover::before {
  opacity: 0.04;
}

/* #sidenav_list > .v-list-item--active:hover::before {
  opacity: 0.12 !important;
} */
#sidenav_list .v-list-group__header {
  padding-left: 30px;
}

#sidenav_list #theme_selection {
  padding-left: 30px;
}

#sidenav_list
  > .v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  margin-left: 0px;
  margin-right: 0px;
  min-width: 24px;
}

.online_icon_bat {
  margin-left: -16px;
  margin-right: 16px;
}
.online_icon {
  margin-left: -16px;
  margin-right: 16px;
}
.disabledCams {
  background-color: rgba(0, 0, 0, 0.12);
}
.cam {
  color: grey;
  cursor: pointer;
  user-select: none;
}
.tt {
  cursor: not-allowed;
}
.camOffline {
  color: grey;
  cursor: not-allowed;
}
.theme_mode {
  cursor: pointer;
}
.camBat {
  user-select: none;
}
.no_pointer {
  cursor: default !important;
}
.camIcon {
  color: inherit;
}
.logo {
  margin-top: 8px !important;
  margin-left: 8px !important;
}
#cameras_link {
  text-decoration: none;
}
html,
body {
  height: 100%;
}
.right-nav {
  background-color: blanchedalmond;
}
#night {
  margin-right: 8px;
}
.sub_sidenav_icon_avatar {
  height: 24px !important;
  min-width: 24px !important;
  width: 24px !important;
}
</style>
