<template>
  <v-dialog v-model="dialog" max-width="900px" min-width="860px">
    <v-card>
      <v-card-text class="pb-0">
        <v-row>
          <v-toolbar elevation="0"
            ><h2 style="color: #0090a4">Add Treatment to</h2>
            <div class="ml-4">
              <v-radio-group
                hide-details
                mandatory
                dense
                v-model="connection_to"
              >
                <v-radio label="Herd" value="herd"></v-radio>
                <v-radio label="Custom" value="custom"></v-radio>
              </v-radio-group>
            </div>

            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn></v-toolbar
          ></v-row
        >

        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-select
              v-if="connection_to == 'herd'"
              outlined
              dense
              v-model="selected_animals_grouping"
              :items="herds"
              label="Herd*"
              class="ma-0 pa-0"
            >
            </v-select>
            <v-autocomplete
              v-else-if="connection_to == 'custom'"
              outlined
              dense
              v-model="selected_animals_grouping"
              :items="animals_to_add"
              label="Animals*"
              multiple
              :search-input.sync="selection_query_params.search_dam"
            >
              <template v-slot:item="{ item }"
                ><v-chip
                  style="color: white; font-weight: 500; margin-bottom: -5px"
                  small
                  :color="
                    item.tag_color
                      ? 'oc_' + item.tag_color.toLowerCase() + ' darken-1'
                      : 'grey darken-1'
                  "
                  >{{ item.text }}</v-chip
                ></template
              >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <masked-field-date
              v-model="create_item.date"
              label="Date*"
              @input="on_field_change('date', $event)"
            ></masked-field-date>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              dense
              v-model="create_item.type"
              :items="enums.treatment_type"
              label="Type *"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              dense
              v-model="create_item.reason"
              label="Reason"
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              dense
              v-model="create_item.vet_name"
              label="Vet Name"
            ></v-text-field
          >
          </v-col>
           <v-col cols="12" sm="6" md="4">
            <masked-field-date
              v-model="create_item.withdraw_date"
              label="Withdraw Date"
              @input="on_field_change('withdraw_date', $event)"
            ></masked-field-date></v-col> 
            <v-col cols="12" sm="6" md="4">
            <masked-field-date
              v-model="create_item.booster_date"
              label="Booster Date"
              @input="on_field_change('booster_date', $event)"
            ></masked-field-date></v-col> 
          <v-col cols="12" class="py-0">
            <span style="font-size: 16px">Drugs: </span>
            <v-icon
              @click="
                drugs.push({
                  id: return_uuid(),
                  customer_id: $store.getters.getCustomerID,
                  name: '',
                  dose: '',
                })
              "
              color="primary"
              >mdi-plus-box-outline</v-icon
            ></v-col
          >
          <v-col cols="12">
            <v-row class="pa-0" v-for="(drug, index) in drugs" :key="index">
              <v-col cols="7"
                ><v-text-field
                  outlined
                  dense
                  v-model="drug.name"
                  label="Name"
                  hide-details
                ></v-text-field
              ></v-col>
              <v-col cols="4"
                ><v-text-field
                  outlined
                  dense
                  v-model="drug.dose"
                  label="Dose (ml)"
                  hide-details
                ></v-text-field
              ></v-col>
              <v-col cols="1"
                ><v-icon
                  class="pt-2"
                  @click="create_item.drugs.splice(index, 1)"
                  color="primary"
                  >mdi-minus-box-outline</v-icon
                ></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="mr-2">
        <v-row>
          <v-col class="text-right">
            <div>
              <v-btn color="gray" width="120" @click="close" class="mr-4">
                Cancel
              </v-btn>
              <v-btn
                style="float: right"
                color="primary"
                width="120"
                @click="create_new_record"
                :disabled="
                  selected_animals_grouping == null ||
                  selected_animals_grouping.length == 0 ||
                  !create_item.date ||
                  !create_item.type ||
                  validateDate(create_item.date) != true
                "
              >
                Save
              </v-btn>
            </div></v-col
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validateDate, validateIsNumber } from "@/utilities/ValidationRules";
import { eventBus } from "../main.js";
import MaskedFieldDate from "../components/masks/MaskedFieldDate.vue";
import { v4 as uuidv4 } from "uuid";
import * as hasura_mutations from "../graphql_hasura/mutations";


import axios from "axios";
import * as hasura_queries from "../graphql_hasura/queries";

export default {
  components: { MaskedFieldDate },
  name: "AddTreatments",
  data() {
    return {
      dialog: false,
      create_item: {},
      drugs: [],
      connection_to: "herd",
      selected_animals_grouping: [],

      selection_query_params: {
        search: null,
      },
    };
  },
  methods: {
    validateDate,
    validateIsNumber,
    return_uuid() {
      return uuidv4();
    },
    on_field_change(field, event) {
      this.create_item[field] = event;
    },
    async create_new_record() {
      delete this.create_item.createdAt;
      delete this.create_item.updatedAt;

      this.create_item.customer_id = this.$store.getters.getCustomerID;
      for (const n in this.create_item) {
        if (typeof this.create_item[n] == "string") {
          this.create_item[n] = this.create_item[n].trim();
          if (this.create_item[n].length == 0) {
            delete this.create_item[n];
          }
        }
      }

      try {
        var temp_create_item = { ...this.create_item };
        temp_create_item.id = uuidv4();
        temp_create_item.animal_treatments = {};

        if (this.drugs.length) {
          temp_create_item.drugs = {};
          temp_create_item.drugs.data = this.drugs;
        }
        if (this.connection_to == "custom") {
          temp_create_item.animal_treatments.data =
            this.selected_animals_grouping;
        } else {
          let herd_response = await axios.post(
            this.$store.getters.getHasuraParams.url,
            {
              query: hasura_queries.animals_by_herd_for_treatments,
              variables: {
                herd_id: { _eq: this.selected_animals_grouping },
              },
            },
            {
              headers: this.$store.getters.getHasuraParams.headers,
            }
          );
          if (herd_response.errors || herd_response.data.errors) {
            throw new Error(
              "GraphQL error: " + herd_response.errors[0].message
            );
          }
          temp_create_item.animal_treatments.data = [];
          for (const i in herd_response.data.data.animal) {
            temp_create_item.animal_treatments.data.push({
              animal_id: herd_response.data.data.animal[i].id,
              customer_id: this.$store.getters.getCustomerID
            });
          }
        }
       // temp_create_item.customer_id = this.$store.getters.getCustomerID
        let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.insert_treatment_one,
              variables: {
                object1: temp_create_item,
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          console.log(temp_create_item)
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }

        this.dialog = false
        this.create_item = {};
        this.drugs = [];
        this.selected_animals_grouping = [];

        eventBus.$emit("add_button_alert", {
          msg: "Successfully created the treatment",
          err: false,
        });

        eventBus.$emit("get_treatments")
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: "Error creating the treatment",
          err: true,
        });
      }
    },
    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.create_item = {};
        this.drugs = [];
        this.selected_animals_grouping = [];
      });
    },
  },

  computed: {
    enums() {
      var enums = {};
      enums.treatment_type = this.$store.getters.getEnums.treatment_type
        ? this.$store.getters.getEnums.treatment_type
        : [];
      enums.drug_administration_type = this.$store.getters.getEnums
        .drug_administration_type
        ? this.$store.getters.getEnums.drug_administration_type
        : [];
      enums.drug_type = this.$store.getters.getEnums.drug_type
        ? this.$store.getters.getEnums.drug_type
        : [];

      return enums;
    },
    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },

    herds() {
      var herds = [];

      for (const i in this.$store.getters.getHerds) {
        herds.push({
          text: this.$store.getters.getHerds[i].name,
          value: this.$store.getters.getHerds[i].id,
        });
      }

      return herds;
    },

    animals_to_add() {
      var selection_animals = [];
      for (const i in this.$store.getters.getSelectionAnimals) {
        selection_animals.push({
          value: { animal_id: this.$store.getters.getSelectionAnimals[i].id, customer_id: this.$store.getters.getCustomerID },
          text: this.$store.getters.getSelectionAnimals[i].tag_label,
          tag_color: this.$store.getters.getSelectionAnimals[i].tag_color,
        });
      }
      return selection_animals;
    },
  },
  created() {
    eventBus.$on("add_treatment_open", () => {
      this.dialog = true;
    });

    this.$store.dispatch(
      "DDB_GET_SELECTION_ANIMALS",
      this.selection_query_params
    );
  },
  watch: {
    connection_to() {
      this.selected_animals_grouping = [];
    },
  },
};
</script>
<style lang="css">
.purebred_true {
  background-color: rgba(104, 104, 104, 0.1);
}
.an_err {
  bottom: 10px;
  position: absolute;
  right: 10px;
}
</style>