<template>
  <v-dialog v-model="active" width="700">
    <v-card class="px-0">
      <!-- <v-card-actions>
        <v-spacer />
        <v-icon @click="active = false">mdi-close</v-icon>
      </v-card-actions> -->
      <v-card-title>What's new?</v-card-title>
      <v-card-text class="">
        <v-list max-height="500" style="overflow: scroll">
          <v-list-group v-model="active_version_0"
            ><template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>1.29.05</v-list-item-title>
              </v-list-item-content> </template
            ><v-list-item>
              <v-list-item-content>
                <v-list-item-title>Notification Update</v-list-item-title>
                <v-list-item-subtitle
                  >Notifications in the side menu are refactored to show time and age of the notification.</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Detailed view of the notification supports image zoom</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Animal Tag in Dam and Sire Drop Menus</v-list-item-title>
                <v-list-item-subtitle
                  >Dam and sire tags are now sorted and color coded in the drop down menus</v-list-item-subtitle
                >
               
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Bug Fixes</v-list-item-title>
                <v-list-item-subtitle
                  >Updated error/access messages across the portal to be more user readable</v-list-item-subtitle
                >
               <v-list-item-subtitle
                  >Groups and herds now update when switching collaborators</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Viewing videos as a collaborator shows videos from the latest uploaded date</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item></v-list-group
          >

          <v-list-group v-model="active_version_1"
            ><template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>1.29.04</v-list-item-title>
              </v-list-item-content> </template
            ><v-list-item>
              <v-list-item-content>
                <v-list-item-title>Notification Update</v-list-item-title>
                <v-list-item-subtitle
                  >Two new additional filters were added to notification bar, notification type and camera name.</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >New notifications will contain images as well as camera name.</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Clicking on the event would open a detailed view of the image.</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Animal Tag in Notes</v-list-item-title>
                <v-list-item-subtitle
                  >Notes table now displays animal tags.</v-list-item-subtitle
                >
               
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Bug Fixes</v-list-item-title>
                <v-list-item-subtitle
                  >Notification settings are now editable.</v-list-item-subtitle
                >
               
              </v-list-item-content>
            </v-list-item></v-list-group
          >
          
           <v-list-group v-model="active_version_2"
            ><template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>1.29.03</v-list-item-title>
              </v-list-item-content> </template
            ><v-list-item>
              <v-list-item-content>
                <v-list-item-title>"What's new?"</v-list-item-title>
                <v-list-item-subtitle
                  >"What's new?" summary window is now accessible from the
                  navigation bar.</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Bug Fixes</v-list-item-title>
                <v-list-item-subtitle
                  >Removed "{}" from under the event
                  calendar</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item></v-list-group
          >
          </v-list
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { eventBus } from "../main.js";
//import version from "../data/version.json";

export default {
  components: {},
  data() {
    return {
      active: false,
      model: 0,
      active_version_0: true,
      active_version_1: false,
      active_version_2: false,
      //  version: version,
    };
  },
  methods: {},

  computed: {},
  created() {
    eventBus.$on("open_version", () => {
      this.active = true;
    });
  },
  watch: {},
};
</script>
<style lang="css">
</style>