/* ---------- CREATES ---------- */

export const insert_site_one = /* GraphQL */ `
  mutation insert_site_one($object1: site_insert_input = {}) {
    insert_site_one(object: $object1) {
      id
    }
  }
`;

export const insert_feedback_one = /* GraphQL */ `
  mutation insert_feedback_one($object1: feedback_insert_input = {}) {
    insert_feedback_one(object: $object1) {
      customer_id
    }
  }
`;

export const insert_tag_one = /* GraphQL */ `
  mutation insert_tag_one($object1: tag_insert_input = {}) {
    insert_tag_one(object: $object1) {
      customer_id
    }
  }
`;

export const insert_lineage_one = /* GraphQL */ `
  mutation insert_lineage_one(
    $object1: lineage_insert_input = {}
    $update_columns: [lineage_update_column!]
  ) {
    insert_lineage_one(
      object: $object1
      on_conflict: { constraint: lineage_pkey, update_columns: $update_columns }
    ) {
      offspring_id
    }
  }
`;

export const insert_herd_one = /* GraphQL */ `
  mutation insert_herd_one($object1: herd_insert_input = {}) {
    insert_herd_one(object: $object1) {
      id
    }
  }
`;

export const insert_user_note_one = /* GraphQL */ `
  mutation insert_user_note_one($object1: user_note_insert_input = {}) {
    insert_user_note_one(object: $object1) {
      comment
      datetime
    }
  }
`;

export const insert_production_history_one = /* GraphQL */ `
  mutation insert_production_history_one(
    $object1: production_history_insert_input = {}
    $update_columns: [production_history_update_column!]
  ) {
    insert_production_history_one(
      object: $object1
      on_conflict: {
        constraint: production_history_pkey
        update_columns: $update_columns
      }
    ) {
      type
      start_datetime
    }
  }
`;

export const insert_pedigree_one = /* GraphQL */ `
  mutation insert_pedigree_one(
    $object1: pedigree_insert_input = {}
    $update_columns: [pedigree_update_column!]
  ) {
    insert_pedigree_one(
      object: $object1
      on_conflict: {
        constraint: pedigree_pkey
        update_columns: $update_columns
      }
    ) {
      animal_id
      tattoo
      registration_number
      registration_date
      registration_association
      registered_name
    }
  }
`;

export const insert_measurement_one = /* GraphQL */ `
  mutation insert_measurement_one(
    $object1: measurement_insert_input = {}
    $update_columns: [measurement_update_column!]
  ) {
    insert_measurement_one(
      object: $object1
      on_conflict: {
        constraint: measurement_pkey
        update_columns: $update_columns
      }
    ) {
      id
      value
    }
  }
`;

export const insert_phenotype_one = /* GraphQL */ `
  mutation insert_phenotype_one(
    $object1: phenotype_insert_input = {}
    $update_columns: [phenotype_update_column!]
  ) {
    insert_phenotype_one(
      object: $object1
      on_conflict: {
        constraint: phenotype_pkey
        update_columns: $update_columns
      }
    ) {
      id
      bcs
      claw
      hoof
      leg
      scrotum
      semen
      teat
      udder
      date
    }
  }
`;

export const insert_drug = /* GraphQL */ `
  mutation insert_drug(
    $objects: [drug_insert_input!] = {}
    $update_columns: [drug_update_column!]
  ) {
    insert_drug(
      objects: $objects
      on_conflict: { constraint: drug_pkey, update_columns: $update_columns }
    ) {
      returning {
        name
        id
        dose
      }
    }
  }
`;

export const insert_animal_treatment = /* GraphQL */ `
  mutation insert_animal_treatment(
    $objects: [animal_treatment_insert_input!] = {}
  ) {
    insert_animal_treatment(objects: $objects) {
      returning {
        animal_id
        treatment_id
      }
    }
  }
`;

export const insert_animal_one = /* GraphQL */ `
  mutation insert_animal_one($object1: animal_insert_input = {}) {
    insert_animal_one(object: $object1) {
      id
    }
  }
`;

export const insert_treatment_one = /* GraphQL */ `
  mutation insert_treatment_one($object1: treatment_insert_input = {}) {
    insert_treatment_one(object: $object1) {
      id
    }
  }
`;

export const insert_zone_one = /* GraphQL */ `
  mutation insert_zone_one($object1: zone_insert_input = {}) {
    insert_zone_one(object: $object1) {
      id
    }
  }
`;

export const insert_event_one = /* GraphQL */ `
  mutation insert_event_one(
    $object1: event_insert_input = {}
    $update_columns: [event_update_column!]
  ) {
    insert_event_one(
      object: $object1
      on_conflict: {
        constraint: event_pkey
        update_columns: $update_columns
      }
    ) {
      id
    }
  }
`;

export const insert_breeding_history_one = /* GraphQL */ `
  mutation insert_breeding_history_one(
    $object1: breeding_history_insert_input = {}
    $update_columns: [breeding_history_update_column!]
  ) {
    insert_breeding_history_one(
      object: $object1
      on_conflict: {
        constraint: breeding_history_pkey
        update_columns: $update_columns
      }
    ) {
      id
    }
  }
`;
/* ---------- UPDATES ---------- */

export const update_customer_by_pk = /* GraphQL */ `
  mutation update_customer_by_pk(
    $_set: customer_set_input
    $pk_columns: customer_pk_columns_input!
  ) {
    update_customer_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
    }
  }
`;

export const update_address_by_pk = /* GraphQL */ `
  mutation update_address_by_pk(
    $_set: address_set_input
    $pk_columns: address_pk_columns_input!
  ) {
    update_address_by_pk(pk_columns: $pk_columns, _set: $_set) {
      customer_id
    }
  }
`;

export const update_notification_preference_by_pk = /* GraphQL */ `
  mutation update_notification_preference_by_pk(
    $_set: notification_preference_set_input
    $pk_columns: notification_preference_pk_columns_input!
  ) {
    update_notification_preference_by_pk(pk_columns: $pk_columns, _set: $_set) {
      customer_id
    }
  }
`;

export const update_share_by_pk = /* GraphQL */ `
  mutation update_share_by_pk(
    $_set: share_set_input
    $pk_columns: share_pk_columns_input!
  ) {
    update_share_by_pk(pk_columns: $pk_columns, _set: $_set) {
      customer_id_owner
    }
  }
`;

export const update_site_by_pk = /* GraphQL */ `
  mutation update_site_by_pk(
    $_set: site_set_input
    $pk_columns: site_pk_columns_input!
  ) {
    update_site_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
    }
  }
`;
export const update_zone_by_pk = /* GraphQL */ `
  mutation update_zone_by_pk(
    $_set: zone_set_input
    $pk_columns: zone_pk_columns_input!
  ) {
    update_zone_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
    }
  }
`;

export const update_tag_by_pk = /* GraphQL */ `
  mutation update_tag_by_pk(
    $_set: tag_set_input
    $pk_columns: tag_pk_columns_input!
  ) {
    update_tag_by_pk(pk_columns: $pk_columns, _set: $_set) {
      label
      color
    }
  }
`;

export const update_animal_image_by_pk = /* GraphQL */ `
  mutation update_animal_image_by_pk(
    $_set: animal_image_set_input
    $pk_columns: animal_image_pk_columns_input!
  ) {
    update_animal_image_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
    }
  }
`;

export const update_animal_by_pk = /* GraphQL */ `
  mutation update_animal_by_pk(
    $_set: animal_set_input
    $pk_columns: animal_pk_columns_input!
  ) {
    update_animal_by_pk(pk_columns: $pk_columns, _set: $_set) {
      zone_id

      vigor
      updated_at
      surrogate_dam_id
      status
      species
      site_id
      site_dod
      site_doa
      sex
      rfid_current
      purpose
      ownership_status
      nurse_info
      name
      last_seen
      keywords
      id
      docility
      herd_id
      conception_type
      created_at
      customer_id
      dam_status
      disposal_code
      dob
      color
      cod
      classification
      breed
      brand
      birth_ease
    }
  }
`;

export const update_lineage_by_pk = /* GraphQL */ `
  mutation update_lineage_by_pk(
    $_set: lineage_set_input
    $pk_columns: lineage_pk_columns_input!
  ) {
    update_lineage_by_pk(pk_columns: $pk_columns, _set: $_set) {
      offspring_id
    }
  }
`;

export const update_animal = /* GraphQL */ `
  mutation update_animal($_set: animal_set_input, $where: animal_bool_exp!) {
    update_animal(where: $where, _set: $_set) {
      affected_rows
    }
  }
`;

export const update_herd_by_pk = /* GraphQL */ `
  mutation update_herd_by_pk(
    $_set: herd_set_input
    $pk_columns: herd_pk_columns_input!
  ) {
    update_herd_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
    }
  }
`;

export const update_user_note_by_pk = /* GraphQL */ `
  mutation update_user_note_by_pk(
    $_set: user_note_set_input
    $pk_columns: user_note_pk_columns_input!
  ) {
    update_user_note_by_pk(pk_columns: $pk_columns, _set: $_set) {
      comment
    }
  }
`;

export const update_treatment_by_pk = /* GraphQL */ `
  mutation update_treatment_by_pk(
    $_set: treatment_set_input
    $pk_columns: treatment_pk_columns_input!
  ) {
    update_treatment_by_pk(pk_columns: $pk_columns, _set: $_set) {
      withdraw_date
      vet_name
      updated_at
      type
      reason
      keywords
      id
      booster_date
      created_at
      customer_id
      date
    }
  }
`;

export const update_animal_treatment_by_pk = /* GraphQL */ `
  mutation update_animal_treatment_by_pk(
    $_set: animal_treatment_set_input
    $pk_columns: animal_treatment_pk_columns_input!
  ) {
    update_animal_treatment_by_pk(pk_columns: $pk_columns, _set: $_set) {
      updated_at
    deleted_at
    customer_id
    created_at
    animal_id
    }
  }
`;

export const update_video_by_pk = /* GraphQL */ `
  mutation update_video_by_pk(
    $_set: video_set_input
    $pk_columns: video_pk_columns_input!
  ) {
    update_video_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
    }
  }
`;
export const update_camera_by_pk = /* GraphQL */ `
  mutation update_camera_by_pk(
    $_set: camera_set_input
    $pk_columns: camera_pk_columns_input!
  ) {
    update_camera_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
    }
  }
`;
export const update_wifi_by_pk = /* GraphQL */ `
  mutation update_wifi_by_pk(
    $_set: wifi_set_input
    $pk_columns: wifi_pk_columns_input!
  ) {
    update_wifi_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
    }
  }
`;
/* ---------- DELETES ---------- */

export const delete_share_by_pk = /* GraphQL */ `
  mutation delete_share_by_pk(
    $customer_id_collaborator: String!
    $customer_id_owner: String!
  ) {
    delete_share_by_pk(
      customer_id_collaborator: $customer_id_collaborator
      customer_id_owner: $customer_id_owner
    ) {
      customer_id_collaborator
    }
  }
`;
