<template>
  <v-dialog v-model="dialog" max-width="900px">
    <v-card max-width="900px">
      <v-card-text class="pb-0">
        <v-row>
          <v-toolbar elevation="0"
            ><h2 style="color: #0090a4">Add Animal</h2>
            <div style="width: 180px" class="ml-4 mb_hidden">
              <v-select
                outlined
                color="primary"
                hide-details
                dense
                v-model="create_item.site_id"
                :items="sites"
                label="Site*"
              ></v-select>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn></v-toolbar
          ></v-row
        >
        <v-row style="display: none !important" class="mb_display">
          <div style="width: 180px" class="ml-4">
            <v-select
              outlined
              color="primary"
              hide-details
              dense
              v-model="create_item.site_id"
              :items="sites"
              label="Site*"
            ></v-select>
          </div>
        </v-row>

        <v-container class="mt-8" v-if="form_params.verified_species == false">
          <v-row class="ma-auto" justify="center">
            <div class="wrapper">
              <v-btn
                class="mx-6"
                @click="form_params.select_species = 'CATTLE'"
                fab
                large
                :color="
                  form_params.select_species == 'CATTLE' ? 'primary' : 'null'
                "
              >
                <v-icon
                  x-large
                  :color="
                    form_params.select_species == 'CATTLE' ? 'white' : 'grey'
                  "
                >
                  mdi-cow
                </v-icon>
              </v-btn>
              <h2 class="mt-3">Cattle</h2>
            </div>
            <div class="wrapper">
              <v-btn
                class="mx-6"
                @click="form_params.select_species = 'SHEEP'"
                fab
                large
                :color="
                  form_params.select_species == 'SHEEP' ? 'primary' : 'null'
                "
              >
                <v-icon
                  x-large
                  :color="
                    form_params.select_species == 'SHEEP' ? 'white' : 'grey'
                  "
                >
                  mdi-sheep
                </v-icon>
              </v-btn>
              <h2 class="mt-3">Sheep</h2>
            </div>
          </v-row>
        </v-container>
        <v-container v-else>
          <v-row
            ><v-tabs
              class="table_tabs pt-1"
              v-model="create_new_tab"
              icons-and-text
            >
              <v-tab data-cy="addAnimal_tab_general"
                >General<v-icon style="display: block">mdi-cow</v-icon></v-tab
              >
              <v-tab data-cy="addAnimal_tab_history"
                >{{ "History & Breeding"
                }}<v-icon style="display: block"
                  >mdi-clipboard-text-clock-outline</v-icon
                ></v-tab
              >
            </v-tabs></v-row
          >

          <v-row
            v-if="create_new_tab == 0"
            :class="'purebred_' + form_params.expand_tag"
          >
            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-text-field
                outlined
                dense
                v-model="create_item.tag.label"
                :label="'Animal Tag or ID*'"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-autocomplete
                outlined
                dense
                v-model="create_item.tag.color"
                label="Tag Color*"
                :items="enums.tag_color"
                hide-no-data
              ></v-autocomplete
            ></v-col>

            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-text-field
                outlined
                dense
                v-model="create_item.rfid_current"
                label="RFID"
              ></v-text-field
            ></v-col>

            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-select
                outlined
                dense
                v-model="create_item.classification"
                :items="enums.sex_classification"
                label="Classification"
                @change="update_sex()"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-select
                outlined
                dense
                v-model="create_item.sex"
                :items="[
                  { value: 'MALE', text: 'Male' },
                  { value: 'FEMALE', text: 'Female' },
                ]"
                :disabled="
                  ['COW', 'FREEMARTIN', 'HEIFER', 'BULL', 'STEER'].includes(
                    create_item.classification
                  )
                "
                label="Sex*"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-select
                outlined
                dense
                v-model="create_item.status"
                :items="enums.animal_status"
                label="Status"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <masked-field-date
                v-model="create_item.dob"
                label="Date of Birth"
                @input="on_field_change('dob', $event)"
              ></masked-field-date>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-text-field
                outlined
                dense
                v-model="create_item.breed"
                label="Breed"
              ></v-text-field
            ></v-col>

            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-text-field
                outlined
                dense
                v-model="create_item.name"
                label="Name"
              ></v-text-field
            ></v-col>

            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-text-field
                outlined
                dense
                v-model="create_item.color"
                label="Color"
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-autocomplete
                outlined
                :items="
                  selection_query_params.active == 'sire' && create_item.dam
                    ? [create_item.dam]
                    : selection_animals
                "
                dense
                v-if="create_item"
                v-model="create_item.dam"
                label="Dam"
                :return-object="true"
                @focus="focus_animal_select('dam')"
                :search-input.sync="selection_query_params.search_dam"
              >
                <template v-slot:item="{ item }">
                  <v-chip
                    style="color: white; font-weight: 500; margin-bottom: -5px"
                    small
                    :color="
                      item.tag_color
                        ? 'oc_' + item.tag_color.toLowerCase() + ' darken-1'
                        : 'grey darken-1'
                    "
                    >{{ item.text }}</v-chip
                  >
                </template>
              </v-autocomplete></v-col
            >
            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-autocomplete
                outlined
                dense
                v-if="create_item"
                v-model="create_item.sire"
                label="Sire"
                :return-object="true"
                :items="
                  selection_query_params.active == 'dam' && create_item.sire
                    ? [create_item.sire]
                    : selection_animals
                "
                @focus="focus_animal_select('sire')"
                :search-input.sync="selection_query_params.search_sire"
                ><template v-slot:item="{ item }">
                  <v-chip
                    style="color: white; font-weight: 500; margin-bottom: -5px"
                    small
                    :color="
                      item.tag_color
                        ? 'oc_' + item.tag_color.toLowerCase() + ' darken-1'
                        : 'grey darken-1'
                    "
                    >{{ item.text }}</v-chip
                  >
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row
            v-if="create_new_tab == 1"
            :class="'purebred_' + form_params.expand_tag"
          >
            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <masked-field-date
                v-model="create_item.site_doa"
                label="Date of Arrival"
                @input="on_field_change('site_doa', $event)"
              ></masked-field-date>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-select
                outlined
                dense
                v-model="create_item.purpose"
                :items="enums.animal_purpose"
                label="Purpose"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-select
                outlined
                dense
                v-model="create_item.ownership_status"
                :items="enums.ownership_status"
                label="Ownership Status"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-select
                outlined
                dense
                v-model="create_item.birth_ease"
                :items="enums.birth_ease"
                label="Birth Type"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-select
                outlined
                dense
                v-model="create_item.nurse_info"
                :items="enums.nurse_info"
                label="Nursing History"
              ></v-select>
            </v-col>
            <!-- <v-col cols="12" sm="6" md="4" class="const_text_field_color">
              <v-select
                :readonly="!create_item.pregnant"
                outlined
                dense
                v-model="create_item.dam_status"
                :items="enums.dam_status"
                label="Pregnant Dam Status"
              >
                <template v-slot:prepend-inner>
                  <v-checkbox
                    class="ma-0 pa-0"
                    hide-details
                    v-model="create_item.pregnant"
                    dense
                  ></v-checkbox
                ></template>
              </v-select>
            </v-col> -->
            <v-col cols="12"
              ><v-row :class="'purebred_' + purebred">
                <v-col cols="12" class="const_text_field_color"
                  ><v-checkbox
                    class="mt-0"
                    v-model="purebred"
                    label="Registered Purebred"
                  ></v-checkbox
                ></v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  class="const_text_field_color"
                  v-if="'pedigrees' in create_item"
                  ><v-text-field
                    outlined
                    dense
                    v-model="create_item.pedigrees.registration_number"
                    label="Registration Number*"
                  ></v-text-field
                ></v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  class="const_text_field_color"
                  v-if="'pedigrees' in create_item"
                >
                  <v-autocomplete
                    outlined
                    dense
                    v-model="create_item.pedigrees.registration_association"
                    label="Registration Association*"
                    :items="[
                      'Canadian Angus Association',
                      'Canadian Belgian Blue Association',
                      'Canadian Blonde dAquitaine Association',
                      'Canadian Charolais Association',
                      'Canadian Galloway Association',
                      'Canadian Gelbvieh Association',
                      'Canadian Hereford Association',
                      'Canadian Highland Cattle Society',
                      'Canadian Limousin Association',
                      'Canadian Lowline Cattle Association',
                      'Canadian Maine-Anjou Association',
                      'Canadian Murray Grey Association',
                      'Salers Association of Canada',
                      'Canadian Shorthorn Association',
                      'Canadian Simmental Association',
                      'Canadian Speckle Park Association',
                    ]"
                    hide-no-data
                  ></v-autocomplete
                ></v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  class="const_text_field_color"
                  v-if="'pedigrees' in create_item"
                  ><v-text-field
                    outlined
                    dense
                    v-model="create_item.pedigrees.registered_name"
                    label="Registration Name*"
                  ></v-text-field
                ></v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  class="const_text_field_color"
                  v-if="'pedigrees' in create_item"
                  ><v-text-field
                    outlined
                    dense
                    v-model="create_item.pedigrees.tattoo"
                    label="Tattoo*"
                  ></v-text-field
                ></v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  class="const_text_field_color"
                  v-if="'pedigrees' in create_item"
                >
                  <masked-field-date
                    v-if="'pedigrees' in create_item"
                    v-model="create_item.pedigrees.registration_date"
                    label="Registration Date*"
                    @input="on_field_change('registration_date', $event)"
                  ></masked-field-date>
                </v-col> </v-row
            ></v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-2 pt-2 mr-2">
        <v-row class="pr-3 pl-5">
          <v-col class="text-left">
            <v-checkbox
              class="add_another_animal_checkbox"
              v-model="add_another_animal"
              label="Add another animal"
              :hide-details="true"
            ></v-checkbox>
          </v-col>
          <v-col class="text-right">
            <div>
              <v-btn color="gray" width="120" @click="close" class="mr-4">
                Cancel
              </v-btn>
              <v-btn
                data-cy="btn_create_animal_save"
                style="float: right"
                color="primary"
                width="120"
                @click="create_new_record"
                :disabled="
                  !create_item.site_id ||
                  !create_item.tag.label ||
                  !create_item.tag.color ||
                  !create_item.sex ||
                  validateDateEmpty(create_item.dob) != true ||
                  (create_item.site_doa != undefined &&
                    !create_item.site_doa.length &&
                    create_item.site_doa &&
                    validateDateEmpty(create_item.site_doa) != true)
                "
              >
                Save
              </v-btn>
            </div></v-col
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  validateDate,
  validateIsNumber,
  validateDateEmpty,
} from "@/utilities/ValidationRules";
import { eventBus } from "../main.js";
import moment from "moment";
import MaskedFieldDate from "../components/masks/MaskedFieldDate.vue";

import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";

import { v4 as uuidv4 } from "uuid";

export default {
  components: { MaskedFieldDate },
  name: "AddAnimals",
  data() {
    return {
      dialog: false,
      create_item: {
        tag: {},
      },
      form_params: {
        select_species: "CATTLE",
        verified_species: true,
        expand_tag: false,
      },
      purebred: false,
      create_new_tab: 0,
      add_another_animal: false,

      add_from_betsy: false,

      selection_query_params: {
        classification: false,
        search: null,
        search_dam: null,
        search_sire: null,
        active: false,
      },
    };
  },
  methods: {
    validateDate,
    validateIsNumber,
    validateDateEmpty,
    focus_animal_select(type) {
      this.selection_query_params = {
        classification: type == "dam" ? "COW" : "BULL",
        search: null,
        search_dam: this.selection_query_params.search_dam,
        search_sire: this.selection_query_params.search_sire,
        active: type,
      };
    },
    update_sex() {
      if (
        ["COW", "FREEMARTIN", "HEIFER"].includes(
          this.create_item.classification
        )
      ) {
        this.create_item.sex = "FEMALE";
      } else if (["BULL", "STEER"].includes(this.create_item.classification)) {
        this.create_item.sex = "MALE";
      }
    },
    on_field_change(field, event) {
      if (field == "registration_date") {
        this.create_item.pedigrees[field] = event;
      } else {
        this.create_item[field] = event;
      }
    },
    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.create_item = {
          tag: {},
        };
        this.form_params = {
          select_species: "CATTLE",
          verified_species: true,
          expand_tag: false,
        };
        this.purebred = false;
      });
    },

    async create_new_record() {
      this.create_item.customer_id = this.$store.getters.getCustomerID;

      this.create_item.species = this.form_params.select_species;

      if (this.create_item.tag.label) {
        this.create_item.tag.label.replaceAll("\t", "");
        this.create_item.tag.label.trim();
      }

      this.create_item.tag.date = moment().format(
        "YYYY-MM-DDTHH:mm:ss.sssZ"
      );

      if (!this.create_item.tag.color) {
        this.create_item.tag.color = "UNDEFINED";
      }

      for (const n in this.create_item) {
        if (typeof this.create_item[n] == "string") {
          this.create_item[n] = this.create_item[n].trim();
          if (this.create_item[n].length == 0) {
            delete this.create_item[n];
          }
        }
      }

      try {
        var temp_create_item = { ...this.create_item };
        temp_create_item.id = uuidv4();

        if (temp_create_item.pedigrees) {
          temp_create_item.pedigrees.customer_id = temp_create_item.customer_id;
          temp_create_item.pedigrees.date = this.registration_date;
          let temp_pedigrees = [temp_create_item.pedigrees];
          temp_create_item.pedigrees = {};
          temp_create_item.pedigrees.data = temp_pedigrees;
        }

        if (temp_create_item.dam || temp_create_item.sire) {
          temp_create_item.lineages = { data: [{customer_id: temp_create_item.customer_id}]};
          if (temp_create_item.dam) {
            temp_create_item.lineages.data[0].dam_id = temp_create_item.dam.value
          }
          if (temp_create_item.sire) {
            temp_create_item.lineages.data[0].sire_id = temp_create_item.sire.value
          }
          
          delete temp_create_item.dam;
          delete temp_create_item.sire;
        }

        if (!this.add_from_betsy) {

          temp_create_item.tag = {data:  this.create_item.tag};
          temp_create_item.tag.data.id = uuidv4(); 
          temp_create_item.tag.data.customer_id = temp_create_item.customer_id
          temp_create_item.tag.data.animal_id = temp_create_item.id


          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.insert_animal_one,
              variables: {
                object1: temp_create_item,
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
        } else {
          temp_create_item.tag = {data:  this.create_item.tag};
          temp_create_item.tag.data.animal_id = temp_create_item.id

          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.insert_animal_one,
              variables: {
                object1: temp_create_item,
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error:");
          }

          let image_response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.update_animal_image_by_pk,
              variables: {
                pk_columns: { id: this.create_item.current_image_id },
                _set: {
                  review_status: "APPROVED",
                  review_datetime: moment().format("YYYY-MM-DDTHH:mm:ss"),
                  animal_id: temp_create_item.id
                },
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (image_response.errors || image_response.data.errors) {
            throw new Error("GraphQL error: ");
          }
          eventBus.$emit("end_loading_animal_ids");
          eventBus.$emit("remove_index");
          this.add_from_betsy = false;
        }

        if (this.add_another_animal) {
          for (const i in this.create_item) {
            if (
              i != "tag" &&
              i != "breed" &&
              i != "sire_id" &&
              i != "dam_id" &&
              i != "classification" &&
              i != "color" &&
              i != "site_id"
            ) {
              delete this.create_item[i];
            }
          }
          this.create_item.tag = {};
        } else {
          this.dialog = false;
          this.purebred = false;

          this.create_item = {
            tag: {},
          };
        }

        this.form_params = {
          select_species: "CATTLE",
          verified_species: true,
          expand_tag: false,
        };
        if (!this.add_from_betsy) {
          eventBus.$emit("get_animals");
        }

        eventBus.$emit("add_button_alert", {
          msg: "Successfully created the animal",
          err: false,
        });
      } catch {
        eventBus.$emit("add_button_alert", {
          msg: "Error creating the animal",
          err: true,
        });
      }
    },
  },

  computed: {
    selection_animals() {
      var selection_animals = [];
      for (const i in this.$store.getters.getSelectionAnimals) {
        selection_animals.push({
          value: this.$store.getters.getSelectionAnimals[i].id,
          text: this.$store.getters.getSelectionAnimals[i].tag_label,
          tag_color: this.$store.getters.getSelectionAnimals[i].tag_color,
        });
      }
      return selection_animals;
    },
    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },

    enums() {
      var enums = {};
      enums.tag_color = this.$store.getters.getEnums.tag_color
        ? this.$store.getters.getEnums.tag_color
        : [];

      enums.sex_classification = this.$store.getters.getEnums.sex_classification
        ? this.$store.getters.getEnums.sex_classification
        : [];
      enums.animal_purpose = this.$store.getters.getEnums.animal_purpose
        ? this.$store.getters.getEnums.animal_purpose
        : [];
      enums.ownership_status = this.$store.getters.getEnums.ownership_status
        ? this.$store.getters.getEnums.ownership_status
        : [];
      enums.birth_ease = this.$store.getters.getEnums.birth_ease
        ? this.$store.getters.getEnums.birth_ease
        : [];
      enums.nurse_info = this.$store.getters.getEnums.nurse_info
        ? this.$store.getters.getEnums.nurse_info
        : [];
      enums.dam_status = this.$store.getters.getEnums.dam_status
        ? this.$store.getters.getEnums.dam_status
        : [];
      enums.animal_status = this.$store.getters.getEnums.animal_status
        ? this.$store.getters.getEnums.animal_status
        : [];
      return enums;
    },
    sites() {
      var sites = [];
      for (const i in this.$store.getters.getSites) {
        sites.push({
          text: this.$store.getters.getSites[i].name,
          value: this.$store.getters.getSites[i].id,
        });
      }
      return sites;
    },
  },
  created() {
    //  if (!eventBus._events["add_animal_open"]) {
    eventBus.$on("add_animal_open", (animal) => {
      if (animal) {
        this.add_from_betsy = true;
        this.create_item = animal;
      } else {
        this.add_from_betsy = false;
      }
      this.dialog = true;
    });
    //   }

    if (!eventBus._events["create_new_animal"]) {
      eventBus.$on("create_new_animal", (animal) => {
        if (animal) {
          this.add_from_betsy = true;
          this.create_item = animal;
          this.create_new_record();
        }
      });
    }
  },
  // destroyed() {
  //   eventBus.$off("create_new_animal");
  // },
  watch: {
    selection_query_params: {
      handler: function () {
        if (this.selection_query_params.active) {
          this.$store.dispatch(
            "DDB_GET_SELECTION_ANIMALS",
            this.selection_query_params
          );
        }
      },
      deep: true,
    },
    dialog() {
      this.add_another_animal = false;
      if (!this.add_from_betsy) {
        this.create_item = {
          tag: {},
          site_id: this.sites[0].value,
        };
      }
      if (!this.dialog) {
        eventBus.$emit("end_loading_animal_ids");
      }
    },
    purebred() {
      if (this.purebred) {
        this.create_item.pedigrees = {};
      } else {
        delete this.create_item.pedigrees;
      }
    },
    create_item: {
      handler: function () {
        if (this.create_item) {
          if (this.create_item.pregnant) {
            this.create_item.sex = "FEMALE";
          } else {
            delete this.create_item.dam_status;
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="css">
.add_another_animal_checkbox {
  margin-top: 0px;
  padding-top: 0px;
}
.purebred_true {
  background-color: rgba(104, 104, 104, 0.1);
}
</style>