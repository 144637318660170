<template>
  <v-dialog v-model="active" width="900">
    <v-card class="px-0">
      <!-- <v-card-actions>
        <v-spacer />
        <v-icon @click="active = false">mdi-close</v-icon>
      </v-card-actions> -->
     
      <v-card-text class="px-0">
       <v-toolbar elevation="0" class="py-0"
              ><h2 style="color: #0090a4" >
                {{slides[model].title}}
              </h2>
              <v-spacer></v-spacer>
              <v-btn icon @click="active = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            >
        <!-- <v-btn
          fab
          x-small
          style="position: absolute; z-index: 1; right: 4px; top: 4px"
        >
          <v-icon @click="active = false" color="primary"
            >mdi-close</v-icon
          ></v-btn
        > -->

        <v-carousel
          v-model="model"
          hide-delimiters
          
          id="tutorial-carousel"
        >
          <v-carousel-item v-for="(slide, i) in slides" :key="i">
            <div class="video-container">
              <video poster="@/assets/loader.png" class="video" autoplay loop>
                <source :src="slide.src" type="video/mp4" />
              </video>
            </div>
            <v-sheet
              min-height="88px"
              class="mt-6"
              style="margin-left: 90px; margin-right: 90px"
            >
            
              <div style="font-size: 18px" class="mt-2">{{ slide.text }}</div>
            </v-sheet>
            <div style="width: 100%; text-align: center; height: 18px; ">
              <span style="font-size: 14px; color: gray; "
                >{{ model + 1 }}/{{ slides.length }}</span
              >
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { eventBus } from "../main.js";

export default {
  components: {},
  data() {
    return {
      active: false,
      model: 0,
      slides: [
        {
          title: "Map to Preview Cameras",
          text: "The map system helps identify the location of Cameras, WIFI, and Zones. Hovering over a camera gives a preview of the current video feed.",
          src: "https://cdn.onecup.ai/videos/1_camera_preview.mp4",
        },
        {
          title: "Choosing Between Sites",
          text: "A ranch may be composed of one or more sites. The site drop-down provides a quick and easy way to flip between locations.",
          src: "https://cdn.onecup.ai/videos/2_sites.mp4",
        },
        {
          title: "Drop Tag Search",
          text: "The drop tag search function is a convenient way to find a specific animal quickly. Type in a text to filter out animals rapidly.",
          src: "https://cdn.onecup.ai/videos/3_drop_tag_search.mp4",
        },
        {
          title: "Dashboards",
          text: "Dashboards provide an overview of what is happening at your ranch. Each dashboard provides a specific role, from cameras to phenotypes.",
          src: "https://cdn.onecup.ai/videos/4_dashboard.mp4",
        },
        {
          title: "Video Player",
          text: "With motion-activated cameras, video footage is organized into one-hour segments. The video player is a quick and easy way to jump to a specific time.",
          src: "https://cdn.onecup.ai/videos/5_video_playback.mp4",
        },
        {
          title: "Table View",
          text: "You can quickly sort or filter by any field in the table view. In-field edits allow a rancher to promptly update any animal, while the image button displays animals’ heads and bodies.",
          src: "https://cdn.onecup.ai/videos/6_edit_inline.mp4",
        },
        {
          title: "Cow Card",
          text: "The cow card is a concise view of everything known about an animal, including medical history, phenotypes and pedigree.",
          src: "https://cdn.onecup.ai/videos/7_cow_card.mp4",
        },
      ],
    };
  },
  methods: {},

  computed: {},
  created() {
    eventBus.$on("open_tutorial", () => {
      this.active = true;
    });
  },
  watch: {},
};
</script>
<style lang="css" scoped>
#tutorial-carousel {
  min-height: 600px !important;
  height: unset !important;
}
#tutorial-carousel > div {
  min-height: 600px !important;
  height: unset !important;
}
#tutorial-carousel > div > div > div {
  min-height: 600px !important;
  height: unset !important;
}
#tutorial-carousel > div > .v-window__next {
  bottom: 46px;
  top: unset;
}
#tutorial-carousel > div > .v-window__prev {
  bottom: 46px;
  top: unset;
}

.video {
  width: 100%;
  height: 100%;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
}

.video-container {
  height: 507px;
}
</style>